<template>
  <!-- background-image: url('https://turfissimo.com/img/capture_race_page.jpg'); -->

  <!-- Background image -->
  <div
    class="text-center relative overflow-hidden bg-no-repeat bg-center bg-cover h-screen background-image"
    :style="inlineBgImage"
  >
    <speedometer-component-vue name="JOCKEY" target="75" />
    <div
      class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
      :style="retroEffect"
      id="title"
    >
      <div class="flex justify-center items-center h-full">
        <div class="text-gray-200 dark:text-gray-200">
          <h2 class="font-bold xl:text-6xl text-5xl mb-4">Turfissimo</h2>
          <h4 class="font-semibold xl:text-3xl text-xl mb-6">
            Des stats pour y voir plus clair !
          </h4>
          <a
            class="inline-block px-7 py-3 mb-1 border-2 border-gray-200 text-gray-200 font-medium text-sm leading-snug uppercase rounded hover:bg-gray-50 hover:bg-opacity-10 focus:outline-none focus:ring-0 transition duration-0 hover:duration-700 ease-in-out"
            href="#explanation"
            @click="animateExplanation"
            role="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            >Comment ça fonctionne ?</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Background image -->
</template>

<script>
import SpeedometerComponentVue from "./effects/SpeedometerComponent.vue";
export default {
  name: "CarouselComponent",
  components: {
    SpeedometerComponentVue,
  },
  computed: {
    inlineBgImage() {
      let bgImage = require("@/assets/capture_race_page.jpg");

      return {
        backgroundImage: `url("${bgImage}")`,
        filter: `blur(8px)`,
        animation: `nettete 1.0s ease-in forwards`,
        width: `100%`,
        height: `100%`,
        backgroundSize: `cover`, /* Utilisation de 100% 100% pour remplir le cadre */
        //transformOrigin: `top`
      };
    },
    retroEffect() {
      return {
        //background: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><line x1='-50%' y1='50%' x2='150%' y2='50%' style='stroke:rgba(1,0,0,0.2);stroke-width:50'/></svg>") repeat`,
        //backgroundSize: `0px, cover`,
        //backgroundBlendMode: `overlay`
        backgroundColor: `rgba(0,0,0,0)`,
        textShadow: `#000 2px 2px 2px`,
      };
    },
  },
  mounted() {
    this.$_handleScroll = () => {
      const percent = (screen.height > screen.width) ? 300 : 100; 
      const speedScaleFactor = (screen.height > screen.width) ? 40 : 100; 
      const background = document.querySelector('.background-image');
      const scrollY = window.scrollY;
      const scaleFactor = (scrollY / speedScaleFactor); // Ajustez ce facteur pour contrôler l'intensité du grossissement
      if(background!==null){
        background.style.backgroundSize = `${percent + scaleFactor * 10}%`;
      }
      if(window.scrollY > 200) {
        this.animateExplanation();
        //window.removeEventListener("scroll", this.$_handleScroll);
      }
    }
    window.addEventListener("scroll", this.$_handleScroll);
  },
  beforeUnmount() {
    // Supprimer l'événement de défilement lorsque le composant est détruit
    window.removeEventListener("scroll", () => {
      this.$_handleScroll;
    });
  },
  methods: {
    animateExplanation() {
      const items = document.getElementsByClassName("item");
      for (let index = 0; index < items.length; index++) {
        items[index].classList.add("animated");
      }
    },
  }
};
</script>

<style>
#title {
  animation-delay: 5s;
  animation: 0.8s apparitionTitle ease-in forwards;
}
@keyframes nettete {
  0% {
    filter: blur(8px);
  }
  100% {
    filter: blur(0);
  }
}
@keyframes apparitionTitle {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes zoomEffect {
  0% {
    opacity: 100%;
    scale: 100%;
  }
  100% {
    opacity: 50%;
    scale: 150%;
  }
}
</style>