<template>
  <div id="home" :class="[getMode == true ? 'dark' : '']" class="w-min-screen h-min-screen bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-400">
    <header class="inline-block">
      <NavbarComponent/>
    </header>
    <router-view>
    </router-view>
  </div>
</template>

<script setup>
import NavbarComponent from './components/NavbarComponent.vue'
import { appDatas } from './stores'
import { computed } from 'vue';

const appDatasState = appDatas();
  
//const darkMode = ref(appDatasState.getDarkMode);

const getMode = computed(() => {
  return appDatasState.getDarkMode
})
</script> 

<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
