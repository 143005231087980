<template>
<div class="relative">
    <Doughnut
        :options="chartOptions"
        :data="chartData"
        :height=height
        :width=width
        :top=top
        />
    <div class="w-11 top-[10.5px] absolute mx-auto text-[14px] text-center font-bold">
        <span class="block m-auto">{{ total }}</span>
    </div>
</div>
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
export default {
    name: 'DoughnutComponent',
    components: { Doughnut },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        chartOptions: {
            type: Object,
            default: () => {}
        },
        height: [Number, String],
        width: [Number, String],
        top: [Number, String]
    },
    mounted(){
        const getTotal=()=>{            
            this.total = this.chartData.datasets[0].data[0]+this.chartData.datasets[0].data[1]+this.chartData.datasets[0].data[2]+this.chartData.datasets[0].data[3]
        }
        getTotal()
    },
    updated(){
        const getTotal=()=>{            
            this.total = this.chartData.datasets[0].data[0]+this.chartData.datasets[0].data[1]+this.chartData.datasets[0].data[2]+this.chartData.datasets[0].data[3]
        }
        getTotal()
    },
    data() {
        return {
            total: null
        }
    }
}
</script>

<style>

</style>