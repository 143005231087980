<template>
<div class="min-h-[calc(100vh-1.5rem)] sm:h-min-[calc(100vh-1.5rem)] pt-12 pb-6 mx-auto bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-400">
    <Transition>
        <div v-if="loaded">
            <h1 class="text-center pb-5 text-3xl font-bold">Laboratoire de stratégies</h1>
            <div class="flex flex-row mt-8 w-1/2 mx-auto">
                <div>
                    <label for="label" class="mr-3">Nom de votre stratégie</label>
                    <input class="
                        py-1
                        px-1 
                        rounded-md 
                        dark:bg-gray-900 
                        border-2 
                        border-gray-600 
                        focus:border-indigo-500
                        active:border-indigo-500
                        " 
                        id="label" 
                        name="label" 
                        type="text"
                        v-model="selection.label"
                    >
                </div>
                <div class="
                    py-1 
                    px-3 
                    w-64 
                    text-center 
                    rounded-full 
                    font-semibold 
                    transition 
                    ease-in-out 
                    delay-150 
                    text-gray-50 
                    hover:text-gray-50 
                    duration-450 
                    mx-auto 
                    cursor-pointer"
                    :class="[checkValidity() ? 'bg-green-600 hover:bg-green-700' : 'bg-orange-600 hover:bg-orange-700 cursor-default']"
                >
                    <button v-if="id === undefined" @click='createStrategy'>Créer stratégie</button>
                    <button v-else @click='updateStrategy(id)'>Modifier Stratégie</button>
                </div>
            </div>
            <div class="flex items-center flex-row justify-center pt-5 mx-auto">
                <!-- Add criterias -->
                <div class="p-3 m-3 border-2 border-gray-600 rounded-md">
                    <h2 class="text-center pb-8 font-bold text-xl">Critères disponibles 🗒️</h2>
                    <div class="grid grid-cols-2">
                        <div class="pb-5" :class="[index%2 ? 'border-l-2 pl-3 dark:border-gray-400 border-gray-600' : '']" v-for="criteria, index in statisticCriterias" :key="criteria.label">
                            <div @click="addCriterias(criteria)">
                                <font-awesome-icon class="w-16 dark:text-gray-400 text-[24px]" :icon=criteria.icon />
                                {{ criteria.label }}
                                <font-awesome-icon class="float-right w-12 text-[16px] pt-2" icon="fa-solid fa-circle-plus" />
                            </div>
                        </div>
                    </div>
                </div>
                <Transition>
                <div v-if="selection.selected.length" class="mx-5 align-bottom">
                    <font-awesome-icon class="text-[36px]" icon="fa-solid fa-arrow-right" />
                </div>
                </Transition>
                <!-- Criterias selection -->
                <Transition>
                <div v-if="selection.selected.length" id="selectedCriterias" class="p-3 m-2 border-2 border-gray-600 rounded-md">
                    <h2 class="text-center pb-8 font-bold text-xl">Critères sélectionnés ✅</h2>
                    <div v-for="select, key in selection.selected" class="mb-5" :key="key">
                        <font-awesome-icon @click="deleteCriterias(key)" class="w-8 text-[16px] pt-2 cursor-pointer" icon="fa-solid fa-circle-minus" />
                        <font-awesome-icon class="w-8 dark:text-gray-400 text-[24px]" :icon=select.icon />
                        {{ select.name }}
                        <span v-if="!checkValidity(key)" class="relative flex mt-2.5 h-3 w-3 float-right">
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-3 w-3 bg-orange-500"></span>
                        </span>
                        <span v-else class="relative flex mt-2.5 h-3 w-3 float-right">
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                        </span>
                        <font-awesome-icon @click="setActualCriteria(select, $event)" class="float-right w-8 text-[16px] pt-2 cursor-pointer" icon="fa-solid fa-flask" />
                    </div>
                </div>
                </Transition>
                <Transition>
                <div v-if="selection.actualCriteria" class="mx-5 align-bottom">
                    <font-awesome-icon class="text-[36px]" icon="fa-solid fa-arrow-right" />
                </div>
                </Transition>
                <!-- Parameters criteria -->
                <Transition>
                <div v-if="selection.actualCriteria" class="p-3 m-2 border-2 border-gray-600 rounded-md">
                    <h2 class="text-center pb-8 font-bold text-xl">Réglages critère : {{ selection.actualCriteria.name }} 🧪</h2>
                    <div class="grid grid-rows-5 grid-flow-col gap-2">
                        <div>
                            <label for="type-classement-select" class="font-semibold mr-5">Classement</label> 
                            <select v-model="selection.actualCriteria.parameters.type_label" name="type-classement" id="type-classement-select" class="w-32 float-right border-2 rounded-md dark:bg-gray-800 border-gray-600 focus:border-indigo-600 active:border-indigo-300">
                                <option value=null></option>
                                <option value="gagnant">Gagnant</option>
                                <option value="place">Placé</option>
                                <option value="itm">Itm</option>
                                <option value="npl">Non placé</option>
                            </select>
                        </div>
                        <div>
                            <label for="type-unit-select" class="font-semibold mr-5">Unité de recherche</label> 
                            <select v-model="selection.actualCriteria.parameters.type_count" class="w-32 float-right border-2 rounded-md dark:bg-gray-800 border-gray-600 focus:border-indigo-600 active:border-indigo-300">
                                <option v-for="option in typeOptions" :value="option.value" :key="option.text" >{{ option.text }}</option>
                            </select>
                        </div>
                        <div>
                            <label for="count-min-select" class="font-semibold mr-5">Minimum recherché</label> 
                            <select v-if="selection.actualCriteria.parameters.type_count === 'percent'" v-model="selection.actualCriteria.parameters.mini_count" name="count-min" id="count-min-select" class="w-32 float-right border-2 rounded-md dark:bg-gray-800 border-gray-600 focus:border-indigo-600 active:border-indigo-300">
                                <option v-for="countOptionUnit in miniCountOptionPercent" :key="'percent-'+countOptionUnit.value" :value=countOptionUnit.value>{{ countOptionUnit.text }}</option>
                            </select>
                            <select v-if="selection.actualCriteria.parameters.type_count === 'unit'" v-model="selection.actualCriteria.parameters.mini_count" name="count-min" id="count-min-select" class="w-32 float-right border-2 rounded-md dark:bg-gray-800 border-gray-600 focus:border-indigo-600 active:border-indigo-300">
                                <option v-for="countOptionUnit in miniCountOptionUnit" :key="'unit-'+countOptionUnit.value" :value=countOptionUnit.value>{{ countOptionUnit.text }}</option>
                            </select>
                        </div>
                        <div>
                            <label for="count-min-races" class="font-semibold mr-5">Minimum de courses</label> 
                            <select v-model="selection.actualCriteria.parameters.mini_total" name="min-races" id="count-min-races" class="w-32 float-right border-2 rounded-md dark:bg-gray-800 border-gray-600 focus:border-indigo-600 active:border-indigo-300">
                                <option v-for="countOptionUnit in miniCountOptionUnit" :key="countOptionUnit.value" :value=countOptionUnit.value>{{ countOptionUnit.text }}</option>
                            </select>
                        </div>
                        <div>
                            <label for="ratio-select" class="font-semibold mr-5">Point(s)</label> 
                            <select v-model="selection.actualCriteria.parameters.ratio" name="ratio" id="ratio-select" class="w-32 float-right border-2 rounded-md dark:bg-gray-800 border-gray-600 focus:border-indigo-600 active:border-indigo-300">
                                <option v-for="countOptionUnit in ratioOption" :key="countOptionUnit.value" :value=countOptionUnit.value>{{ countOptionUnit.text }}</option>
                            </select>
                        </div>
                        <DoughnutComponent
                        v-if="selection.actualCriteria.parameters.type_count === 'percent' 
                            && selection.actualCriteria.parameters.type_label
                            && selection.actualCriteria.parameters.mini_count"
                        class="row-span-5 p-5"
                        :chartOptions="options"
                        :chartData="getData()"
                        updateMode="show"
                        :width=120
                        :height=120
                        :top=45
                    />
                    </div>
                    
                </div>
                </Transition>
            </div>
            <div class="flex items-center flex-row justify-center pt-5 mx-auto">
                <!-- Add Filters -->
                <div class="p-3 m-3 border-2 border-gray-600 rounded-md">
                    <h2 class="text-center pb-8 font-bold text-xl">Filtres courses disponibles 🏇🏽</h2>
                    <div class="grid grid-cols-1">
                        <div>
                            <div class="pb-5">
                                <font-awesome-icon class="w-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-horse-head" />
                                Discipline
                                <font-awesome-icon @click="addFilter('discipline')" class="float-right w-12 text-[16px] pt-2 cursor-pointer" icon="fa-solid fa-circle-plus" />
                            </div>
                            <div class="pb-5">
                                <font-awesome-icon class="w-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-arrows-left-right" />
                                Distance
                                <font-awesome-icon @click="addFilter('distance')" class="float-right w-12 text-[16px] pt-2 cursor-pointer" icon="fa-solid fa-circle-plus" />
                            </div>
                            <div class="pb-5">
                                <font-awesome-icon class="w-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-sack-dollar" />
                                Allocation
                                <font-awesome-icon @click="addFilter('allocation')" class="float-right w-12 text-[16px] pt-2 cursor-pointer" icon="fa-solid fa-circle-plus" />
                            </div>
                            <div class="pb-5">
                                <font-awesome-icon class="w-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-chart-line" />
                                Cote du "matin"
                                <font-awesome-icon @click="addFilter('cote')" class="float-right w-12 text-[16px] pt-2 cursor-pointer" icon="fa-solid fa-circle-plus" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selection.actualFilter" class="mx-5 align-bottom">
                    <font-awesome-icon class="text-[36px]" icon="fa-solid fa-arrow-right" />
                </div>
                <!-- Filters selection -->
                <div v-if="selection.actualFilter" class="p-3 m-2 border-2 border-gray-600 rounded-md">
                    <h2 class="text-center pb-8 font-bold text-xl">Réglages filtre : {{ selection.actualFilter.name }} 🧪</h2>
                    <div v-if="selection.actualFilter.name === 'discipline'">
                        <div v-for="option in disciplineOptions" :key="option.value">
                            <input class="mr-2" type="checkbox" v-model="selection.actualFilter.discipline[option.value]" name="option.value" />
                            <label for="option.value">{{ option.text }}</label>
                        </div>
                    </div>
                    <div v-if="selection.actualFilter.name === 'distance'">
                        <div class="mb-5">Distance minimum : <input type="number" v-model="selection.actualFilter.distance.min"></div>
                        <div>Distance maximum : <input type="number" v-model="selection.actualFilter.distance.max"></div>
                    </div>
                    <div v-if="selection.actualFilter.name === 'allocation'">
                        <div class="mb-5">Allocation minimum : <input type="number" v-model="selection.actualFilter.allocation.min"></div>
                        <div>Allocation maximum : <input type="number" v-model="selection.actualFilter.allocation.max"></div>
                    </div>
                    <div v-if="selection.actualFilter.name === 'cote'">
                        <div class="mb-5">Cote minimum : <input type="number" v-model="selection.actualFilter.cote.min"></div>
                        <div>Cote maximum : <input type="number" v-model="selection.actualFilter.cote.max"></div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import { turfissimo } from '../services/turfissimo'
import DoughnutComponent from '@/components/DoughnutComponent.vue';
import { strategies } from '@/stores';
import { useRouter } from 'vue-router';

const statisticCriterias = ref([])
const router = useRouter()
const loaded = ref(false)
const id = router.currentRoute.value.params?.id

const selection = reactive({
    label: "Nouvelle stratégie",
    miniTotal: 0,
    selected: [],
    actualCriteria: null,
    filters: {
        discipline: {
            'attele': true,
            'monte': true,
            'plat': true,
            'haie': true,
            'steeple': true,
            'cross': true
        },
        racecourse: [],
        distance: {
            min: 0,
            max: 8000
        },
        allocation: {
            min: 4000,
            max: 10000000
        },
        cote: {
            min: 0,
            max: 200
        }
    },
    actualFilter: null
})

// const iconMatrix = {
//     Hippodrome: "fa-solid fa-map-location-dot",
//     Distance: "fa-solid fa-arrows-left-right",
//     Terrain: "fa-solid fa-cloud-sun-rain",
//     Jockey: "fa-solid fa-user",
//     Artifices: "fa-solid fa-gear",
//     Discipline: "fa-solid fa-trophy",
//     Allocation: "fa-solid fa-sack-dollar",
//     "Valeur handicap": "fa-solid fa-weight-scale",
//     "Forme saisonnière": "fa-solid fa-calendar"
// }

onMounted(async () => {
    const responseStatisticCriterias = await turfissimo.getStatisticCriterias();
    loaded.value = true;
    statisticCriterias.value = responseStatisticCriterias.data;
    if(id){
        console.log(id)
        const strategyToUpdate = strategies().getStrategies.find(element => element.id == id)
        selection.label = strategyToUpdate.label
        selection.miniTotal = strategyToUpdate.mini_total
        for (const criteria of strategyToUpdate.statistic_criterias) {
            selection.selected.push({
                name: criteria.statistic_criteria.label,
                icon: criteria.statistic_criteria.icon,
                parameters: {
                    statistic_criteria_id: criteria.statistic_criteria_id,
                    type_label: criteria.type_label,
                    type_count: criteria.type_count,
                    mini_count: criteria.mini_count,
                    mini_total: criteria.mini_total,
                    ratio: criteria.ratio,
                }
            })
        }
        selection.filters.discipline.attele = strategyToUpdate.filter_discipline.includes('attele')
        selection.filters.discipline.monte = strategyToUpdate.filter_discipline.includes('monte')
        selection.filters.discipline.plat = strategyToUpdate.filter_discipline.includes('plat')
        selection.filters.discipline.haie = strategyToUpdate.filter_discipline.includes('haie')
        selection.filters.discipline.steeple = strategyToUpdate.filter_discipline.includes('steeple')
        selection.filters.discipline.cross = strategyToUpdate.filter_discipline.includes('cross')
        selection.filters.racecourse = []
        selection.filters.distance.min = strategyToUpdate.filter_distance_mini
        selection.filters.distance.max = strategyToUpdate.filter_distance_max
        selection.filters.allocation.min = strategyToUpdate.filter_alloc_mini
        selection.filters.allocation.max = strategyToUpdate.filter_alloc_max
        selection.filters.cote.min = strategyToUpdate.cote_matin_min
        selection.filters.cote.max = strategyToUpdate.cote_matin_max
    }
})

const addCriterias = (criteria) => {
    selection.selected.push({
        name: criteria.label,
        icon: criteria.icon,
        parameters: {
            statistic_criteria_id: criteria.id,
            type_label: null,
            type_count: null,
            mini_count: null,
            mini_total: null,
            ratio: null,
        }
    })
}

const deleteCriterias = (id) => {
    console.log('delete')
    selection.selected.splice(id,1)
}

const setActualCriteria = (select, event) => {
    // console.log('setActual Criteria')
    // console.log(event.currentTarget.parentNode.parentElement.childNodes)
    let array = [...event.currentTarget.parentNode.parentElement.childNodes];
    array.map((element) => element?.classList?.remove("border-2","border-indigo-500","rounded-md"));
    event.currentTarget.parentNode.classList.add("border-2","border-indigo-500","rounded-md");
    selection.actualCriteria = select
}

const options = ref ({
    responsive: false,
    maintainAspectRatio: false,
    animation: {
        duration:350,
        delay:400,
        easing:'easeOutCirc'
    },
    plugins: {
        legend: {
            display: false
        },
        title:{
            display: false,
            text: '15',
            align: 'align',
            fullSize: false 
        }
    }
})

const getData = () => {
    let gagnant = selection.actualCriteria.parameters.type_label === 'gagnant' ? true : 0
    let place = selection.actualCriteria.parameters.type_label === 'place' ? true : 0
    let itm = selection.actualCriteria.parameters.type_label === 'itm' ? true : 0
    let np = selection.actualCriteria.parameters.type_label === 'npl' ? true : 0

    if (gagnant === true) {
        gagnant = selection.actualCriteria.parameters.mini_count
        np = 100 - selection.actualCriteria.parameters.mini_count
    }
    if (place === true) {
        place = selection.actualCriteria.parameters.mini_count
        np = 100 - selection.actualCriteria.parameters.mini_count
    }
    if (itm === true) {
        itm = selection.actualCriteria.parameters.mini_count
        np = 100 - selection.actualCriteria.parameters.mini_count
    }

    return {
        labels: ['GAG', 'PLA', 'ITM', 'NPL'],
        datasets: [
            {
                backgroundColor: ['#166534', '#84cc16', '#fb923c', '#dc2626'],
                borderColor: '#222',
                cutout: '70%',
                animation: {
                animateRotate:true,
                animateScale:true
                }, 
                data: [gagnant, place, itm, np] 
            }
        ]
    }
}

const miniCountOptionUnit = [
    { text:"", value:null},
    { text:"1", value:1 },
    { text:"2", value:2 },
    { text:"3", value:3 },
    { text:"4", value:4 },
    { text:"5", value:5 },
    { text:"6", value:6 },
    { text:"7", value:7 },
    { text:"8", value:8 },
    { text:"9", value:9 },
    { text:"10", value:10 },
]

const miniCountOptionPercent = [
    { text:"", value:null},
    { text:"5%", value:5 },
    { text:"10%", value:10 },
    { text:"15%", value:15 },
    { text:"20%", value:20 },
    { text:"25%", value:25 },
    { text:"30%", value:30 },
    { text:"35%", value:35 },
    { text:"40%", value:40 },
    { text:"45%", value:45 },
    { text:"50%", value:50 },
    { text:"55%", value:55 },
    { text:"60%", value:60 },
    { text:"65%", value:65 },
    { text:"70%", value:70 },
    { text:"75%", value:75 },
    { text:"80%", value:80 },
    { text:"85%", value:85 },
    { text:"90%", value:90 },
    { text:"95%", value:95 },
    { text:"100%", value:100 },
]

const ratioOption = [
    { text:"", value:null},
    { text:"1.00", value:1 },
    { text:"1.50", value:1.5 },
    { text:"2.00", value:2 },
    { text:"2.50", value:2.5 },
    { text:"3.00", value:3 },
    { text:"3.50", value:3.5 },
    { text:"4.00", value:4 },
    { text:"4.50", value:4.5 },
    { text:"5.00", value:5 }
]

const typeOptions = [
    { text: '', value: null },
    { text: 'Pourcentage', value: 'percent' },
    { text: 'Unitaire', value: 'unit' },
] 

const addFilter = (name) => {
    selection.actualFilter = Object.keys(selection.filters).filter((key) => key.includes(name)).
    reduce((cur, key) => { return Object.assign(cur, { [key]: selection.filters[key] })}, {});
    selection.actualFilter.name = name;
}

const disciplineOptions = [
    { text: 'Attelé', value: 'attele' },
    { text: 'Monté', value: 'monte' },
    { text: 'Plat', value: 'plat' },
    { text: 'Haies', value: 'haie' },
    { text: 'Steeple-chase', value: 'steeple' },
    { text: 'Cross', value: 'cross' },
]

const createStrategy = () => {
    const data = formatDataToApiCall();
    //console.log(data);
    turfissimo.createStrategy(data);
    strategies().resetStrategies()
}

const updateStrategy = (id) => {
    const data = formatDataToApiCall();
    turfissimo.updateStrategy(id, data);
    strategies().resetStrategies()
}

function formatDataToApiCall() {
    let items = [];

    selection.selected.forEach((criteria) => {
        items.push(criteria.parameters)
    })

    let filterDiscipline = []

    Object.keys(selection.filters.discipline).forEach(function(key) {
        if(selection.filters.discipline[key]){
            filterDiscipline.push(key);
        }
    })

    return {
        label: selection.label,
        mini_total: selection.miniTotal,
        items: items,
        filter_discipline: filterDiscipline,
        filter_racecourse: selection.filters.racecourse,
        filter_distance_mini: selection.filters.distance.min,
        filter_distance_max: selection.filters.distance.max,
        filter_alloc_mini: selection.filters.allocation.min,
        filter_alloc_max: selection.filters.allocation.max,
        cote_matin_min: selection.filters.cote.min,
        cote_matin_max: selection.filters.cote.max    
    }
}

function checkValidity(key=null) {
    //console.log(key)
    if(key === null){
        if(selection.selected.length > 0){
            for(const criteria of selection.selected) {
                if(!criteria.parameters.mini_count) return false;
                if(!criteria.parameters.mini_total) return false;
                if(!criteria.parameters.ratio) return false;
                if(!criteria.parameters.statistic_criteria_id) return false;
                if(!criteria.parameters.type_count) return false;
                if(!criteria.parameters.type_label) return false;
            }
            return true
        } else {
            return false
        }
    } else {
        //console.log(selection.selected[key])
        if(!selection.selected[key].parameters.mini_count) return false;
        if(!selection.selected[key].parameters.mini_total) return false;
        if(!selection.selected[key].parameters.ratio) return false;
        if(!selection.selected[key].parameters.statistic_criteria_id) return false;
        if(!selection.selected[key].parameters.type_count) return false;
        if(!selection.selected[key].parameters.type_label) return false;
        return true;
    }
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>