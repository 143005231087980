import axios from 'axios'
import { appDatas } from '@/stores'

//const baseUrl = "http://localhost:8000/api"
const baseUrl = process.env.VUE_APP_API_URL

const turfissimo = {
    login: async (loginInformation) => {
        await axios.post(baseUrl+'/login', loginInformation)
            .then(response => {
                //this.$root.access_token = response.data.access_token;
                localStorage.setItem('turfissimo_access_token', response.data.access_token);
                appDatas().setUserToken(response.data.access_token)
                axios.get(baseUrl+'/user', {
                    headers: {
                        Authorization: 'Bearer ' + response.data.access_token
                    }
                }).then(response => {
                    localStorage.setItem('turfissimo_user', JSON.stringify(response.data));
                    //this.$root.authUser = JSON.parse(localStorage.getItem('turfissimo_user'));
                    //this.$router.push({ name: "race"}); 
                    appDatas().setUserInfos(response.data)
                });
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    throw error;
                }
            })
    },
    refreshToken: async () => {
        axios.post(
            baseUrl+'/refresh'
        ).then(response => {
            //this.$root.access_token = response.data.access_token;
            localStorage.setItem('turfissimo_access_token', response.data.access_token);
            axios.get(baseUrl+'/user', {
                headers: {
                    Authorization: 'Bearer ' + response.data.access_token
                }
            }).then(response => {
                localStorage.setItem('turfissimo_user', JSON.stringify(response.data));
                //this.$root.authUser = JSON.parse(localStorage.getItem('turfissimo_user'));
            })
        });
    },
    logout: async () => {
        axios.post(
            baseUrl+'/logout',null, {
                headers:{
                    'Authorization': 'Bearer '+localStorage.getItem('turfissimo_access_token'),
                    'content-type': 'application/json' 
                }
            }
        ).then( () => {
            localStorage.removeItem('turfissimo_access_token')
            localStorage.removeItem('turfissimo_user')
        })
    },
    register: (registrationInformation) => {
        axios.post(
            baseUrl+'/register', registrationInformation
        ).then(response => {
            //this.$root.access_token = response.data.access_token;
            localStorage.setItem('turfissimo_access_token', response.data.access_token);
            axios.get(baseUrl+'/user', {
                headers: {
                    Authorization: 'Bearer ' + response.data.access_token
                }
            }).then(response => {
                localStorage.setItem('turfissimo_user', JSON.stringify(response.data));
                //this.$root.authUser = JSON.parse(localStorage.getItem('turfissimo_user'));
                //this.$router.push({ name: "race"}); 
            });
        }).catch((error) => {
            if (error.response.status == 422) {
                if (error.response.data.errors.password != undefined) {
                    throw error;
                }
                if (error.response.data.errors.email != undefined) {
                    throw error;
                }
            }
        })
    },
    getMeeting: (formatDate) => {
        return axios.get(
            baseUrl+'/meetings?include=races,racecourse,racecourse.country,racesCount&filter[starting_date]=' + formatDate
        )
    },
    getRaceResult: (raceId) => {
        const results = axios.get(
            baseUrl+'/races/' + raceId + '?include=meeting.racecourse,runners,runners.horse,runners.trainer,runners.rider,runners.owner,runners.distance'
        );
        return  results
    },
    getRaceData: (raceId) => {
        const datas = axios.get(
            baseUrl+'/runners?include=horse,rider,trainer,distance&filter[race_id]=' + raceId
        );
        return datas;
    },
    getGlobalStats: (raceId) => {
        const stats = axios.get(baseUrl+'/races/globalStats/' + raceId);
        return stats;
    },
    getBlogArticlesList: () => {
        return axios.get(
            baseUrl+'/blog?fields[articles]=id,title,url_image,accroche,created_at'
        )
    },
    getArticle: (articleId) => {
        return axios.get(
            baseUrl+'/blog?fields[articles]=id,title,accroche,url_image,content,created_at&filter[id]=' + articleId
        )
    },
    getStrategies: () => {
        return axios.get(baseUrl+'/strategies?include=statisticCriterias.statisticCriteria', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('turfissimo_access_token')
            }
        })
    },
    getPastResults: (runner_id, date) => {
        return axios.get(baseUrl+'/runners/'+runner_id+'/'+date+'?include=race.meeting.racecourse,horse,rider');
    },
    getStatisticCriterias: async () => {
        return await axios.get(baseUrl+'/statistic-criterias');
    },
    createStrategy : async (data) => {
        const response = await axios.post(baseUrl+'/strategy', 
            data,
            {
                headers:{
                    'Authorization': 'Bearer '+localStorage.getItem('turfissimo_access_token'),
                    'content-type': 'application/json' 
                }
            }
        );
        if (response.status === 200) {
            console.log('Stratégie créée');
            console.log(response.data)
        } else {
            console.log('status : '+response.status);
            console.log(response.data)
        }
    },
    updateStrategy : async (id, data) => {
        const response = await axios.put(baseUrl+'/strategy/'+id,
            data,
            {
                headers:{
                    'Authorization': 'Bearer '+localStorage.getItem('turfissimo_access_token'),
                    'content-type': 'application/json' 
                }
            }
        );
        if (response.status === 200) {
            console.log('Stratégie modifiée');
            console.log(response.data)
        } else {
            console.log('status : '+response.status);
            console.log(response.data)
        }
    },
    getStrategySelectionByDate : async (id, date) => {
        return await axios.get(baseUrl+'/strategy/'+id+'/date/'+date+'/0',
            {
                headers:{
                    'Authorization': 'Bearer '+ localStorage.getItem('turfissimo_access_token'),
                    'content-type': 'application/json' 
                }
            }
        );
        // if (response.status === 200) {
        //     return response.data
        // } else {
        //     console.log('status : '+response.status);
        //     console.log(response.data)
        // }
    },
    getCurrentFormStatistic : async (duration) => {
        return await axios.get(baseUrl+'/statistic/topFive?duration='+duration,
            {
                headers:{
                    'Authorization': 'Bearer '+ localStorage.getItem('turfissimo_access_token'),
                    'content-type': 'application/json' 
                }
            }
        );
    }
}

export { turfissimo }