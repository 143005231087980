import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

import 'tw-elements'

import router from './router/index.js'

/*import vue-datepicker */
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import './assets/style.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { 
    faMoon,
    faSun,
    faCircle,
    faMapLocationDot,
    faArrowsLeftRight,
    faCloudSunRain,
    faUser,
    faGear,
    faTrophy,
    faSackDollar,
    faCalendar,
    faWeightScale,
    faA,
    faCircleXmark,faCircleInfo,faHorseHead,faEye,
    faBackwardStep,faForwardStep,faSpinner,
    faCirclePlus,faArrowRight,
    faCircleMinus,
    faFlask,
    faChartLine,
    faStar
} from '@fortawesome/free-solid-svg-icons'

/* import specific icons */
import { faStar as faStarReg } from '@fortawesome/free-regular-svg-icons'


/* add icons to the library */
library.add(
    faSun,
    faMoon, 
    faCircle, 
    faMapLocationDot, 
    faArrowsLeftRight, 
    faCloudSunRain,
    faUser,
    faGear,
    faTrophy,
    faSackDollar,
    faCalendar,
    faWeightScale,
    faA,
    faCircleXmark,faCircleInfo,faHorseHead,faEye,
    faBackwardStep,faForwardStep,faSpinner,
    faCirclePlus,faArrowRight,
    faCircleMinus,
    faFlask,
    faChartLine,
    faStar,
    faStarReg)

const pinia = createPinia()

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.component('Datepicker', Datepicker)
.use(router)
.use(pinia)
.mount('#app')
