<template>
<div>
  <div 
    :class="[params?.R ? 'block md:hidden' : 'hidden md:hidden']"
    class="w-full px-2  
    bg-gray-300 
    text-gray-600 
    dark:text-gray-400 
    dark:bg-gray-900 
    fixed 
    border-t-[1px] 
    border-b-[1px] 
    border-gray-300 
    dark:border-gray-600 z-20
    font-sans
    font-semibold
    "
    >
      <span v-if="params?.date"><router-link :to="{ name: 'Dashboard', param: { date: params?.date }}">Réunion {{ params?.R }} </router-link></span>
      <span v-if="params?.C" @click="resetPastResult" > > Course {{ params?.C }} </span>
      <span v-if="params?.horseId" @click="resetPastResultDetails" > > Partant </span>
      <span v-if="params?.raceId" > > Perf </span>
  </div>
  <div class="relative pt-[25px] md:pt-[17px] h-[calc(100vh-4rem)] z-10">
    <race-details v-if="raceInfo && !visibility" :key="raceId"></race-details>
    <race-runner-card-component
      v-if="raceInfo && !visibility"
      :key="raceId"
      @get-past-results="getPastResults"
    />
    <PastResultsView
      v-if="visibility"
      class="absolute w-full min-h-full dark:bg-gray-800 dark:text-gray-400 bg-gray-200 text-gray-600 md:overflow-hidden"
      :runners="runners"
      :numSelected="numSelected"
      :name="runnerName"
      :runnerId="runnerId"
      :date="runnerDate"
      :statCriterias="statCriterias"
      @reset-past-result="resetPastResult"
    ></PastResultsView>
  </div>
</div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import RaceRunnerCardComponent from '@/components/RaceRunnerCardComponent.vue';
import PastResultsView from './PastResultsView.vue';
import RaceDetails from '@/components/RaceDetailsComponent.vue';
import { meetingView, selectedPastResult, selectedRace, selectedRunner } from '@/stores';
import { useRouter } from 'vue-router';

const router = useRouter();
const params = ref(router.currentRoute.value.params);

const raceId = ref(meetingView().getSelectedRace);
const visibility = ref(selectedRunner().getPastResults !== null && params?.value?.horseId);
const selectedRaceState = selectedRace();
const raceInfo = ref(selectedRaceState.getInfos);

const selectedPastResultState = selectedPastResult();
const selectedRunnerState = selectedRunner();

// Define reactive variables
const runnerId = ref('');
const runnerName = ref('');
const runnerDate = ref('');
const runners = ref([]);
const numSelected = ref(0);
const statCriterias = ref([]);

const getPastResults = (name, id, date, runnersData, numSelectedData, statCriteriasData) => {
  runnerId.value = id;
  runnerName.value = name;
  runnerDate.value = date;
  runners.value = runnersData;
  statCriterias.value = statCriteriasData;
  numSelected.value = numSelectedData;
  router.push({ name: 'RunnerPast', params: { 'horseId': id, 'horseSlug': name.replace(/\s/g, '-').toLowerCase() } });
  if (!visibility.value) {
    visibility.value = !visibility.value;
  }
};

const resetPastResult = () => {
  // Reset your past result logic here
  selectedPastResultState.resetState();
  selectedRunnerState.resetState();
  router.push({
    name: "Racecard",
    params: {
      R: params?.value.R,
      C: params?.value.C,
      slug: params?.value.slug.replace(/\s/g, "-").toLowerCase(),
    },
  });
  visibility.value = !visibility.value;
};

const resetPastResultDetails = () => {
  router.back();
  selectedPastResultState.resetState();
}

watchEffect(() => {
  raceInfo.value = selectedRaceState.getInfos;
  params.value = router.currentRoute.value.params;
  visibility.value = params?.value?.horseId;
});
</script>

<style>
</style>