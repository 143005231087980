<template>
  <form
    class="md:w-[50%] xl:w-[35%] rounded-lg border-2 dark:border-gray-800 shadow-lg grid grid-cols-1 bg-white dark:bg-gray-900 text-gray-600 dark:text-gray-400 p-2"
  >
    <h3 class="text-xl font-semibold mx-auto">Inscription</h3>
    <br />
    <div class="p-3">
      <label for="name">Nom</label
      ><input
        type="text"
        v-model="form.name"
        placeholder="Nom"
        class="float-right pl-1 dark:bg-gray-900 dark:border-gray-600 border-gray-200 border-solid border-2 rounded-md"
        required
      />
    </div>
    <div class="p-3">
      <label for="email">Email</label
      ><input
        type="text"
        v-model="form.email"
        placeholder="email@email.com"
        class="float-right pl-1 dark:bg-gray-900 dark:border-gray-600 border-gray-200 border-solid border-2 rounded-md"
        required
      />
    </div>
    <div class="p-3">
      <label for="password">Mot de passe</label
      ><input
        type="password"
        v-model="form.password"
        placeholder="password"
        class="float-right pl-1 dark:bg-gray-900 dark:border-gray-600 border-gray-200 border-solid border-2 rounded-md"
        required
      />
    </div>
    <div class="p-3">
      <label for="password_confirmation">Confirmation mot de passe</label
      ><input
        type="text"
        v-model="form.password_confirmation"
        placeholder="repeat password"
        class="float-right pl-1 dark:bg-gray-900 dark:border-gray-600 border-gray-200 border-solid border-2 rounded-md"
        required
      />
    </div>
    <div
      class="px-3 py-1 rounded-full font-semibold transition ease-in-out delay-50 text-gray-50 bg-green-700 hover:bg-green-800 hover:text-gray-50 duration-400 mx-auto cursor-pointer"
    >
      <button @click.prevent="registerUser" type="submit" variant="success">
        S'inscrire
      </button>
    </div>
  </form>
</template>

<script>
import { turfissimo } from '@/services/turfissimo';
export default {
  name: "RegisterView",
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errors: null,
    };
  },
  methods: {
    registerUser() {
      // axios
      //   .post("/api/register", this.form)
      //   .then((response) => {
      //     this.$root.access_token = response.data.access_token;
      //     localStorage.setItem(
      //       "turfissimo_access_token",
      //       response.data.access_token
      //     );
      //     axios
      //       .get("/api/user", {
      //         headers: {
      //           Authorization: "Bearer " + response.data.access_token,
      //         },
      //       })
      //       .then((response) => {
      //         localStorage.setItem(
      //           "turfissimo_user",
      //           JSON.stringify(response.data)
      //         );
      //         this.$root.authUser = JSON.parse(
      //           localStorage.getItem("turfissimo_user")
      //         );
      //         this.$router.push({ name: "race" });
      //       });
      //   })
      //   .catch((error) => {
      //     if (error.response.status == 422) {
      //       if (error.response.data.errors.password != undefined) {
      //         this.errors = error.response.data.errors.password;
      //       }
      //       if (error.response.data.errors.email != undefined) {
      //         this.errors = error.response.data.errors.email;
      //       }
      //     }
      //   });
      turfissimo.register(this.form)
    },
  },
};
</script>

<style>
</style>