import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Dashboard from '../views/DashboardView.vue'
import PastResults from '../views/PastResultsView.vue'
import RaceResults from'../components/RaceResults.vue'
import Blog from '../views/BlogView.vue'
import StrategyDaily from '../views/StrategyDailyView.vue'
import RegisterView from '../views/auth/RegisterView.vue'
import LoginView from '../views/auth/LoginView.vue'
import StrategyFormView from '../views/StrategyFormView.vue'
import StrategySelectionView from '../views/StrategySelectionView.vue'

const routes = [
    { 
        path: '/',
        name: 'Home',
        component: Home,
        meta: { transitionName: 'fade' }
    },
    { 
        path: '/dashboard/:date',
        name: 'Dashboard',
        component: Dashboard,
        meta: { transitionName: 'fade' },
        props: true,
        children: [
            {
                path: 'R:R/C:C/:slug',
                name: 'Racecard',
                component: Dashboard,
                meta: { transitionName: 'fade' },
                props: true,
                children: [
                    {
                        path: 'partant/:horseId/:horseSlug',
                        name: 'RunnerPast',
                        component: Dashboard,
                        meta: { transitionName: 'fade' },
                        props: true,
                        children: [
                            {
                                path: 'details/:raceId',
                                name: 'RunnerPastRaceDetails',
                                component: Dashboard,
                                meta: { transitionName: 'fade' },
                                props: true
                            }
                        ]
                    }
                ]
            }
        ]
    },
    { 
        path: '/blog',
        name: 'Blog',
        component: Blog,
        children: [
            {
                path:'article/:article_id',
                name:'article',
                component : Blog
            }
        ]
    },
    { 
        path: '/strategy',
        name: 'Strategy',
        component: StrategyDaily
    },
    { 
        path: '/strategy/create',
        name: 'StrategyCreation',
        component: StrategyFormView,
        props: true
    },
    { 
        path: '/strategy/selection',
        name: 'StrategySelection',
        component: StrategySelectionView
    },
    {
        path: '/strategy/:id/update',
        name: 'StrategyUpdate',
        component: StrategyFormView,
        props: true
    },
    {
        path: '/cheval/:name/:runnerId/:date',
        name: 'FicheCheval',
        component: PastResults,
        props: true
    },
    {
        path: '/course/:raceId/:raceName',
        name: 'FicheCourse',
        component: RaceResults,
        props: true
    },
    {
        path:'/register',
        component : RegisterView,
        name:'register'
    },
    {
        path:'/sign-in',
        component : LoginView,
        name:'signin'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return { el: to.hash }
        }
    },
})

export default router