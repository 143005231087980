<template>
  <div
    class="min-h-[calc(100vh-1.5rem)] sm:h-min-[calc(100vh-1.5rem)] mt-0 bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-400"
  >
    <Transition>
      <div v-if="loaded">
        <div class="pt-12 pb-6">
          <h1 class="text-3xl font-bold text-center mb-10">🎯 Vos stratégies</h1>
          <p
            v-if="!isConnected" class="p-6 max-w-[90%] xl:max-w-[60%] mx-auto border-2 rounded-md dark:border-gray-800 shadow-lg"
          >
            🏇💥 Des stratégies, tous les jours, pour tous ! 🆓💯 Rejoignez notre
            communauté en tant que membre gratuit et bénéficiez de deux stratégies
            gratuites quotidiennes ! 📈💪 Pour les parieurs connectés, une stratégie
            personnalisée vous attend, basée sur vos préférences et vos historiques
            <!-- 🎯🏆 Mais devenez Premium pour accéder à 10 stratégies exclusives et
            consultez vos historiques de jeu pour devenir un vrai pro des paris
            hippiques ! 🚀🤩 Chez nous, la victoire est à portée de clic ! 💰👑 -->
          </p>

          <div v-show="strategiesDatas?.length != 0 && (strategiesDatas?.length < 3 || isAdmin ) " 
          class="mt-8 py-1 px-3 w-64 text-center rounded-full font-semibold transition ease-in-out delay-150 text-gray-50 bg-green-700 hover:bg-green-800 hover:text-gray-50 duration-450 mx-auto cursor-pointer">
            <router-link :to="{ name: 'StrategyCreation' }">
              Créer une nouvelle stratégie
            </router-link>
          </div>
          
          <div v-if="strategiesDatas?.length != 0 && isConnected" class="flex flex-row flex-wrap justify-center mt-8 max-w-[90%] xl:max-w-[60%] mx-auto">
            <strategy-card
              v-for="strategy in strategiesDatas"
              :key="strategy.id"
              class="p-2 w-[100%] my-2 sm:w-60 sm:m-2"
              :strategy="strategy"
            />
          </div>
          
          <div v-show="!strategiesDatas">
            <router-link
            v-show="signin === false"
            :to="{ name: 'signin' }">
              <div
                
                class="mt-8 p-1 w-64 rounded-full text-center font-semibold transition ease-in-out delay-50 text-gray-50 bg-green-700 hover:bg-green-800 hover:text-gray-50 duration-400 mx-auto cursor-pointer"
              >
                Déjà inscrit ? Je me connecte !
              </div>
            </router-link>
            <router-link
            v-show="signin === true"
            :to="{ name: 'register' }">
              <div
                class="mt-8 p-1 w-64 rounded-full text-center font-semibold transition ease-in-out delay-50 text-gray-50 bg-green-700 hover:bg-green-800 hover:text-gray-50 duration-400 mx-auto cursor-pointer"
              >
                Pas inscrit ? Je m'enregistre !
              </div>
            </router-link>
            
            <div v-show="signin === false" class="flex flex-row justify-center pt-8">
              <RegisterForm />
            </div>
            <div v-show="signin === true" class="flex flex-row justify-center pt-8">
              <SigninView />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { turfissimo } from "@/services/turfissimo";
import RegisterForm from "../components/RegisterFormComponent.vue";
import StrategyCard from "../components/strategy/CardComponent.vue";
import SigninView from "./auth/LoginView.vue";
import { strategies, appDatas } from "@/stores";
import { ref, onMounted, onBeforeMount, computed } from "vue";

//const isAdmin = ref(appDatas().getUserInfos?.id === 1)
//const isConnected = ref(appDatas().getUserInfos !== null)

const signin = ref(false)
const loaded = ref(false)

const appDatasState = appDatas();
const isAdmin = computed(() => {
  return appDatasState.getUserInfos?.id === 1
})
const isConnected = computed(() => {
  return localStorage.getItem('turfissimo_user') !== null
})

const strategiesState = strategies();
const strategiesDatas = computed(() => {
  return strategiesState.getStrategies
})

const fetchDataFromApi = (async() => {
  try {
    const response = await turfissimo.getStrategies();
    strategiesState.loadStrategies(response.data);
  } catch (error) {
    await turfissimo.refreshToken();
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    
  }
})

onBeforeMount(async() => {
  if(strategiesState.getStrategies===null){
    fetchDataFromApi();
  }
}),
onMounted(() => {
  loaded.value = true
}),
function login() {
  signin.value = true;
},
function register() {
  signin.value = false
}


</script>

<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>