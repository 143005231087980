const handlerSession = {
    setSessionStorageItemWithExpiration : (key, value, expirationInMinutes) => {
        const expirationDate = new Date().getTime() + expirationInMinutes * 60000; // calcul de la date d'expiration en millisecondes
        const item = {
        value: value,
        expiration: expirationDate
        };
        sessionStorage.setItem(key, JSON.stringify(item)); // stockage de l'élément avec la date d'expiration
    },

    getSessionStorageItem : (key) => {
        const item = JSON.parse(sessionStorage.getItem(key));
        if (item && new Date().getTime() < item.expiration) {
            return item.value;
        }
        sessionStorage.removeItem(key);
        return null;
    }
}
export { handlerSession }
  
  