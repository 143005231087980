<template>
  <div class="px-4 py-2 md:p-2">
    <h2 class="text-md md:text-xl pb-1 font-medium">
      R{{ race.num_meeting }}C{{ race.num_race }} - {{ race.name }} -
      {{ race.discipline }} - {{ race.category }} - {{ race.total_prize }}€ -
      {{ race.distance }}m. - {{ race.penetrometer_label }} ({{
        race.penetrometer_value
      }})
    </h2>
    <div class="text-sm md:text-sm">{{ race.conditions }}</div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { selectedRace } from "@/stores";
const selectedRaceState = selectedRace();
const race = ref( selectedRaceState.getInfos );

watchEffect(() => {
  race.value = selectedRaceState.getInfos
})
</script>

<style>
</style>