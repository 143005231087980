<template>
    <button class="
    grid 
    grid-flow-col
    gap-0
    mb-2
    mx-auto
    w-[95%]
    rounded-md
    shadow-md"

    @click="emitRaceId(props.race.id)">
        <div class="
        dark:bg-gradient-to-br 
        from-blue-600 
        to-blue-600 
        dark:text-blue-900
        bg-blue-900 
        rounded-l-md 
        shadow-md 
        h-[100%]
        grid-cols-2
        shadow-blue-900/50 
        text-gray-50 
        sm:text-xl md:text-xl lg:text-2xl font-bold text-center px-1">
            <h3 class=" font-bold">C{{ race.num_race }}</h3>
            <span class="text-sm m-0 block">{{ new Date(race.date).toLocaleTimeString('fr-FR', {hour: '2-digit', minute:'2-digit'}) }}</span>
            <font-awesome-icon :class="race.validated === 1 ? 'text-red-600 dark:text-red-400 shadow-lg shadow-red-500/20' : 'text-green-600 dark:text-green-400 shadow-lg shadow-green-500/50'" class="text-sm" icon="fa-solid fa-circle" />
        </div>
        <div class="w-60 text-left mr-2 pl-2">

            <div class="col-span-2 text-base font-medium dark:font-bold">
                <h3>{{ race.name.length > 40 ? race.name.slice(0,35) : race.name}}{{race.name.length > 40 ? "..." : "" }}</h3>
            </div>
            <div class="col-span-3 text-[12px] dark:font-medium">
                <p> {{ race.discipline }} - {{ race.distance }}m - {{ race.total_prize }}€ </p>
                <p> {{ race.num_runners }} partants </p>
            </div>
        </div>            
    </button>
</template>

<script setup>
//import { useRouter } from 'vue-router'
import { defineProps } from 'vue'
import { meetingView, selectedRace, selectedRunner, selectedPastResult } from '@/stores'
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
    race: Object,
})

const emitRaceId = (id) => {
    if(meetingView().getSelectedRace != id){
        selectedPastResult().resetState();
        selectedRunner().resetState();
        selectedRace().resetState();
        meetingView().loadSelectedRace(id);
        router.push({ name: 'Racecard', params: {'R': props.race.num_meeting,'C': props.race.num_race, 'slug': props.race.name.replaceAll(' ','-').toLowerCase() }});
    }
}
</script>

<style>

</style>