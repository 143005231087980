<template>
    <div
        class="min-h-[calc(100vh-1.5rem)] sm:h-min-[calc(100vh-1.5rem)] mt-0 bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-400"
    >
        <div class="w-full md:w-3/5 md:m-auto pt-12 pb-6">
            <h1 class="
                text-3xl 
                font-bold 
                text-center 
                mb-10">
                Les sélections du jour
            </h1>
            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div v-if="selectedHorses" class="overflow-hidden">
                        <table class="min-w-full text-center text-sm">
                        <thead class="border-b font-medium dark:border-neutral-500 text-left md:pl-5">
                            <tr>
                            <th scope="col" class="px-6 py-2">Date</th>
                            <th scope="col" class="px-6 py-2">Réunion</th>
                            <th scope="col" class="px-6 py-2">Chevaux</th>
                            <!-- <th scope="col" class="px-6 py-2">Lien</th> -->
                            </tr>
                        </thead>
                        <tbody class="">
                            <!-- <tr v-for="horse in selectedHorses.data" :key="horse.id" class="border-b dark:border-neutral-500">

                                <td class="whitespace-nowrap left px-6 py-2">{{ 'R'+horse.race_meeting+' - '+horse.racecourse }}</td>
                                <td class="whitespace-nowrap left px-6 py-2">{{ horse.race_meeting*100 + horse.results[0].runner_num }}</td>
                                <td class="whitespace-nowrap left px-6 py-2">{{ horse.results[0].runner_name }}</td>
                                <td class="whitespace-nowrap left px-6 py-2">@mdo</td>
                            </tr> -->
                            <tr v-for='result in selectedHorses.data' :key="result.id" class="border-b dark:border-neutral-800">
                                <td class="text-left pl-5">{{ result.race_date_format }}</td>
                                <td class="text-left pl-5"><span class="font-semibold">R{{ result.race_meeting }}</span> - {{ result.racecourse }}</td>									
                                <td class="text-left pl-5 py-2">
                                    <p v-for='horse in result.results' :key="horse.id" class=""><span class="font-semibold">{{  result.race_num * 100 + horse.runner_num }}</span> - {{ horse.runner_name }} {{ horse.runner_result===null ? '':' - ( '+horse.runner_result }}
                                        <span v-if="horse.runner_result !==null "> à {{ horse.runner_odd_pm }}/1 </span>
                                        {{ horse.runner_result===null ? '':' )' }}
                                    </p>
                                </td>
                                <!-- <td class="">
                                    <div class="px-2 flex-col font-bold"><span
                                        class="p-1 rounded-full border-solid border-[2px] border-gray-400 hover:border-gray-600">📽️
                                        <a :href=getLink(result) target="_blank">Lien replay</a></span></div>
                                </td>								 -->
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { turfissimo } from '@/services/turfissimo';
import { computed, onBeforeMount } from 'vue';
import { strategies } from '@/stores'

const strategiesState = strategies();
const selectedHorses = computed(() => { 
    return strategiesState.getTodaySelection
})

// const getLink = (result) => {
//     return "https://www.equidia.fr/courses/"+result.race_date_meeting+"/R"+result.race_meeting+"/C"+result.race_num+"#player"
// }

onBeforeMount(async()=>{
    if(strategiesState.getTodaySelection === null){
        const response = await turfissimo.getStrategySelectionByDate(2, new Date().toISOString().slice(0,10))
        strategiesState.loadTodaySelection(response.data) 
    }
})
//console.log(selectedHorses)
</script>