<template>
  <div id="pastResultsComponent" v-if="runnerData !== null" class="flex flex-col">
    <div v-if="runnerData?.length == 0" class="text-center">inédit</div>
    <div v-if="runnerData?.length" class="inline-block">
      <div class="overflow-auto w-full md:w-[720px] h-[55vh] mx-3 font-sans">
        <div
          class="table m-auto dark:bg-gray-900 dark:text-gray-400 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600">
          <thead
            class="sticky top-0 py-2 sm:text-sm md:text-md text-center font-bold bg-green-900 dark:bg-green-300 text-white dark:text-green-800">
            <tr class="">
              <th scope="col" class="w-[80px] m-auto rounded-tl-md">Date</th>
              <th scope="col" class="w-[60px] m-auto">Class.</th>
              <th  v-if="runnerData[0].horse.discipline === 'TROT'" scope="col" class="w-[60px] m-auto">Red.Km</th>
              <th scope="col" class="w-[90px] m-auto">Style</th>
              <th scope="col" class="w-[120px] text-left">Hippodrome</th>
              <th scope="col" class="w-[120px] text-left">Jockey</th>
              <th scope="col" class="w-[35px] m-auto">Art.</th>
              <th v-if="runnerData[0].horse.discipline !== 'TROT'" scope="col" class="w-[35px] m-auto">
                Val.
              </th>
              <th scope="col" class="w-[70px] m-auto">Dist.</th>
              <th v-if="runnerData[0].horse.discipline !== 'TROT'" scope="col" class="w-[100px] text-left">
                Terrain
              </th>
              <th scope="col" class="w-[40px] m-auto">Alloc.</th>
              <th scope="col" class="w-[35px] m-auto rounded-tr-md">Cote</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(runner, index) in runnerData" :key="index" :class="{ 'actived': selectedRow === index }"
              class="pb-3 sm:text-[12px] lg:pb-2 text-center font-normal cursor-pointer hover:text-gray-50 hover:bg-indigo-400 active:bg-indigo-500 focus-within:ring-violet-300"
              @click="getDetails(runner.race, index)">
              <td class="w-[80px] m-auto">
                {{ new Date(runner.date).toLocaleString("fr-FR").slice(0, 10) }}
              </td>
              <td class="w-[60px] m-auto font-bold">
                {{ getIncidentInfo(runner.classement, runner.incident)
                }} <span class="font-normal text-[10px]"> {{ runner.race.discipline.slice(0,1).toLowerCase() }}</span>
              </td>
              <td  v-if="runner.horse.discipline === 'TROT'">{{ getRedKm(runner.red_km) }}</td>
              <td class="w-[90px] m-auto" :title="runner.commentaire">
                <span class="text-left p-0.5"><font-awesome-icon class="text-[12px]" :class="getStyleCategory(runner.commentaire) === 4
                    ? getClassTW(runner.classement, true)
                    : 'dark:text-gray-400 text-gray-400'
                  " icon="fa-solid fa-horse-head" /></span>
                <span class="text-left p-0.5"><font-awesome-icon class="text-[12px]" :class="getStyleCategory(runner.commentaire) === 3
                    ? getClassTW(runner.classement, true)
                    : 'dark:text-gray-400 text-gray-400'
                  " icon="fa-solid fa-horse-head" /></span>
                <span class="text-left p-0.5"><font-awesome-icon class="text-[12px]" :class="getStyleCategory(runner.commentaire) === 2
                    ? getClassTW(runner.classement, true)
                    : 'dark:text-gray-400 text-gray-400'
                  " icon="fa-solid fa-horse-head" /></span>
                <span class="text-left p-0.5"><font-awesome-icon class="text-[12px]" :class="getStyleCategory(runner.commentaire) === 1
                    ? getClassTW(runner.classement, true)
                    : 'dark:text-gray-400 text-gray-400'
                  " icon="fa-solid fa-horse-head" /></span>
              </td>
              <td class="text-left w-[120px]">
                {{ capitalize(runner.race.meeting.racecourse.short_label, 20) }}
              </td>
              <td class="text-left w-[120px]">
                {{ runner.rider.name }}
              </td>
              <td class="w-[35px] m-auto">
                <span v-if="runner.def" class="text-[8px] text-gray-50 ml-3 px-1 rounded-full align-middle"
                  :class="getDisplayDef(runner.def).color">{{ getDisplayDef(runner.def).shortLabel }}</span>
                <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'"
                  class="font-bold text-[10px] text-[#dc2626] dark:text-red-400 ml-1"
                  title="Oeillères australiennes">(A)</span>
                <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'"
                  class="font-bold text-[12px] text-[#dc2626] dark:text-red-400 ml-1"
                  title="Oeillères pleines"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></span>
              </td>
              <td v-if="runner.horse.discipline !== 'TROT'" class="w-[35px] m-auto">
                {{ runner.ranking }}
              </td>
              <td class="w-[70px] m-auto">{{ runner.race.distance }}m</td>
              <td v-if="runner.horse.discipline !== 'TROT'" class="w-[100px] text-left">
                {{ capitalize(runner.race.penetrometer_label, 20) }}
              </td>
              <td class="w-[40px] m-auto">
                {{ runner.race.total_prize / 1000 }}K€
              </td>
              <td class="w-[35px] m-auto">{{ runner.odd_pm }}/1</td>
            </tr>
          </tbody>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="w-full text-center">
    <LoaderComponent/>
  </div>
</template>

<script setup>
import {
  getClassementFromRunner,
  capitalize,
  displayDef,
  getRedKm
} from "@/services/utils/orderStatCriteriasData.js";
import { selectedRunner } from "@/stores";
import { ref, defineEmits, watchEffect } from 'vue'
import LoaderComponent from "../LoaderComponent.vue";

//const router = useRouter()

const emits = defineEmits(['getDetails']);

const selectedRunnerState = selectedRunner();
const runnerData = ref(selectedRunnerState.getPastResults);

const getClassTW = (classement, fromStyle = false) => {
  let color = "text-red-600";
  if (classement <= 5) {
    color = "text-orange-500";
  }
  if (classement <= 3) {
    color = "text-lime-500";
  }
  if (classement == 1) {
    color = "text-green-800";
  }
  return fromStyle ? color : color + " text-base w-[3px] h-[3px] font-bold";
};

const getStyleCategory = (comment) => {
  if (
    /(à l'arrière du peloton|dans le dernier tiers du peloton|de l'arrière-garde|à l'arrière-garde|antépénultième|pointé au dernier rang|patienté à l'arrière-garde|lanterne rouge|fermé la marche|dernier du peloton|encore dernier|patient aux derniers rangs|patient au dernier rang|toujours aux derniers rangs|toujours au dernier rang|attentiste|en retrait|venu du dernier tiers|venu du derniers tiers|venu des derniers|en dernière position|en queue de peloton|en fin de peloton|dernier dans le peloton|vient en dernier lieu|parmi les derniers|se fait ramener|est venu des derniers rangs|avant-derni)/i.test(
      comment
    )
  ) {
    return 4; // "attentiste";
  } else if (
    /(vite derrières les premiers|toujours en bon rang|le dos de l'animat|quatrième durant le parcours|quatrième position|cinquième position|sixième position|vite en bon rang|derrière le groupe de tête|en embuscade|sillage du coanimat|deuxième ligne|troisième position|a proximité des leaders|derrière les leaders|derriere les leaders|dans le sillage des leaders|dans le sillage des premiers|dans le sillage de l'animat|derrière l'animat|derrière les animat|dans le sillage des animat|dans le groupe de tête|dans le groupe de tête)/i.test(
      comment
    )
  ) {
    return 2; // "derrière les leaders";
  } else if (
    /(à la pointe du combat|au côté de l'animat|a animé l'épreuve|a animé la course|grande animat|grand animat|installé au commandement|installée au commandement|extérieur de l'animat|animateur|rabattu au commandement|rabattu en tête|en tête|en tete|en tête dès le départ|vite aux avants-postes|vite aux avant-postes|au contact de l'animat|accompagné l'animat|en tete des le depart|en tête du peloton|en tete du peloton|tête et corde)/i.test(
      comment
    )
  ) {
    return 1; // "course en tête";
  } else if (
    /(n'a joué aucun rôle|venu du sein du peloton|mi-peloton|seconde moitié du peloton|troisième ligne|quatrième ligne|sur un troisième rideau|sur une troisième ligne|au sein du peloton|au milieu du peloton|dans le peloton|au cœur du peloton|dans le ventre du peloton)/i.test(
      comment
    )
  ) {
    return 3; //"au sein du peloton";
  } else {
    return 5;
  }
};

const getIncidentInfo = (classement, incident) => {
  return getClassementFromRunner(classement, incident);
};
const getDisplayDef = (def) => {
  return displayDef(def);
};

let selectedRow = ref(null);

const getDetails = (race, index) => {
  // Si une ligne est déjà sélectionnée, réinitialiser son état
  if (selectedRow.value !== null) {
    selectedRow.value = null;
  }

  // Sélectionner la nouvelle ligne
  selectedRow.value = index;
  emits("getDetails", race);
}

watchEffect(() => {
  runnerData.value = selectedRunnerState.getPastResults;
})

//const filterDiscipline = ref(!runnerData?.value[0]?.horse?.discipline === "TROT")

// onMounted(() => {
//   if (runnerData.value !== null) {
//     filterDiscipline = !runnerData.value[0].horse.discipline === "TROT";
//   }
// })


</script>

<style>
tr.actived td {
  background-color: rgba(79, 70, 229, 1);
  color: white;
}

</style>