<template>
  <div class="flex flex-row justify-between dark:bg-gray-800 mt-2 md:mt-3">
    <div class="flex-none bg-white dark:bg-gray-900 w-7 h-7 mt-1 md:w-10 md:h-10 md:mt-0 rounded-full
      shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      @click="changeSelectionByArrow('decrement')">
      <button class="pl-2 pt-0.5 md:pl-3.5 md:pt-2">
        <span><font-awesome-icon icon="fa-solid fa-backward-step" /></span>
      </button>
    </div>
    <div class="grow h-8 md:h-12">
      <Listbox as="div" v-model="selected">
        <div class="relative mx-2 md:mx-5">
          <ListboxButton
            class="relative w-full cursor-default rounded-md font-bold dark:bg-gray-900 bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
            <span class="flex items-center">
              <img :src="selected.url_silks" alt="" class="w-5 h-6 md:w-6 md:h-7 flex-shrink-0 rounded-full" />
              <span class="ml-3 block truncate">{{ selected.number }}</span>
              <span class="ml-3 block truncate">{{ selected.horse.name }}</span>
              <span v-if="selected.blinkers === 'OEILLERES_AUSTRALIENNES'"
                class="ml-3 block truncate font-bold text-[10px] text-[#dc2626] dark:text-red-400" title="Oeillères australiennes">(A)</span>
              <span v-if="selected.blinkers === 'OEILLERES_CLASSIQUE'"
                class="ml-3 block truncate font-bold text-[12px] text-[#dc2626] dark:text-red-400" title="Oeillères pleines"><font-awesome-icon
                  icon="fa-solid fa-circle-xmark" /></span>
              <div v-if="selected.def" :class="displayDef(selected.def).color"
                class="text-[8px] text-gray-50 ml-3 block truncate px-1 rounded-full">{{ displayDef(selected.def).shortLabel
                }}</div>
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md dark:bg-gray-900 bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption as="template" v-for="runner in runners" :key="runner.id" :value="runner"
                v-slot="{ active, selected }">
                <li @click="changeSelection(runner.horse_id, runner.horse.name)"
                  :class="[active ? 'bg-indigo-600 dark:bg-indigo-900 text-white' : 'text-gray-600', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <div class="flex items-center">
                    <img :src="runner.url_silks" alt="" class="h-7 flex-shrink-0 rounded-full" />
                    <span class="ml-3 block truncate">{{ runner.number }}</span>
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{
                      runner.horse.name }}</span>
                  </div>

                  <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div
      class="flex-none bg-white dark:bg-gray-900 w-7 h-7 mt-1 md:w-10 md:h-10 md:mt-0 rounded-full
       shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      @click="changeSelectionByArrow('increment')">
      <button class="pl-2.5 pt-0.5 md:pl-4 md:pt-2">
        <span><font-awesome-icon icon="fa-solid fa-forward-step" /></span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, getCurrentInstance, defineEmits } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
const router = useRouter();
const instance = getCurrentInstance();
const attrs = instance.attrs;
//const name = attrs.name;
const runners = attrs.runners;
const findIndex = (name, runners) => {
  for (let i = 0; i < runners.length; i++) {
    if (runners[i].horse.name === name) {
      return i;
    }
  }
}
const emits = defineEmits(['changeSelection'])

const changeSelectionByArrow = (type) => {
  if (type === "increment") {
    if (selected.value.number >= runners.length) {
      changeSelection(runners[0].horse_id, runners[0].horse.name)
    } else {
      changeSelection(runners[selected.value.number].horse_id, runners[selected.value.number].horse.name)
    }
  }
  if (type === "decrement") {
    if (selected.value.number - 2 < 0) {
      changeSelection(runners[runners.length - 1].horse_id, runners[runners.length - 1].horse.name)
    } else {
      changeSelection(runners[selected.value.number - 2].horse_id, runners[selected.value.number - 2].horse.name)
    }
  }
}

const changeSelection = (id, name) => {
  selected.value = runners[findIndex(name, runners)]
  attrs.name = name;
  router.push({ name: 'RunnerPast', params: { 'horseId': id, 'horseSlug': name.replace(/\s/g, '-').toLowerCase() } });
  emits('changeSelection', id)
}

const displayDef = (def) => {
  if (def === 'DEFERRE_ANTERIEURS_POSTERIEURS') return { shortLabel: 'D4', color: 'bg-red-800' }
  if (def === 'DEFERRE_POSTERIEURS') return { shortLabel: 'DP', color: 'bg-green-800' }
  if (def === 'DEFERRE_ANTERIEURS') return { shortLabel: 'DA', color: 'bg-green-800' }
  if (def === 'DEFERRE_ANTERIEURS_PROTEGE_POSTERIEURS') return { shortLabel: 'DAPP', color: 'bg-orange-800' }
  if (def === 'PROTEGE_ANTERIEURS_DEFERRRE_POSTERIEURS') return { shortLabel: 'PADP', color: 'bg-orange-800' }
  if (def === 'PROTEGE_ANTERIEURS_POSTERIEURS') return { shortLabel: 'P4', color: 'bg-orange-800' }
  if (def === 'PROTEGE_ANTERIEURS') return { shortLabel: 'PA', color: 'bg-orange-800' }
  if (def === 'PROTEGE_POSTERIEURS') return { shortLabel: 'PP', color: 'bg-orange-800' }
}

const selected = ref(runners[findIndex(attrs.name, runners)])
</script>