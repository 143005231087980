<template>
  <div
    class="h-[calc(100vh-3.5em)] w-screen mt-8 bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-50 "
  >
    <div v-if="errors != null">
      <div v-for="e in errors" :key="e">
        <div>{{ e }}</div>
      </div>
    </div>
    <div class="flex flex-cols md:items-center md:justify-center h-[calc(100vh-3.5em)]">
      <RegisterForm/>
    </div>
  </div>
</template>
<script>
import RegisterForm from '../../components/RegisterFormComponent.vue';
import axios from "axios";

export default {
  name: "RegisterView",
  components: {
    RegisterForm
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errors: null,
    };
  },
  methods: {
    registerUser() {
      axios
        .post("/api/register", this.form)
        .then((response) => {
          this.$root.access_token = response.data.access_token;
          localStorage.setItem(
            "turfissimo_access_token",
            response.data.access_token
          );
          axios
            .get("/api/user", {
              headers: {
                Authorization: "Bearer " + response.data.access_token,
              },
            })
            .then((response) => {
              localStorage.setItem(
                "turfissimo_user",
                JSON.stringify(response.data)
              );
              this.$root.authUser = JSON.parse(
                localStorage.getItem("turfissimo_user")
              );
              this.$router.push({ name: "race" });
            });
        })
        .catch((error) => {
          if (error.response.status == 422) {
            if (error.response.data.errors.password != undefined) {
              this.errors = error.response.data.errors.password;
            }
            if (error.response.data.errors.email != undefined) {
              this.errors = error.response.data.errors.email;
            }
          }
        });
    },
  },
};
</script>