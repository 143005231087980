<template>
  <div v-if="raceData && raceInfo" class="pt-3 flex flex-col font-sans">
    <div class="p-2 flex-col ">
      <h2 class="text-md font-bold">
        {{ new Date(raceInfo.date).toLocaleString("fr-FR").slice(0, 10) }} - {{ raceInfo.meeting.racecourse.short_label }}
        - {{ raceInfo.name }} -
        {{ raceInfo.discipline }} - {{ raceInfo.category }} - {{ raceInfo.total_prize }}€ -
        {{ raceInfo.distance }}m. - {{ raceInfo.penetrometer_label }} ({{
          raceInfo.penetrometer_value
        }})
      </h2>
      <div class="text-sm hidden md:block">{{ raceInfo.conditions }}</div>
    </div>
    <div v-if="raceInfo.discipline != 'ATTELE' && raceInfo.discipline != 'MONTE'" class="p-2 mb-2 flex-col font-bold">
      <span class="p-1 rounded-full border-solid border-2 border-gray-400 bg-white dark:bg-gray-900">📈 Valeur moyenne du
        lot : {{ averageRating }}</span></div>
    <div class="px-2 flex-col font-bold"><span
        class="p-1 rounded-full border-solid border-2 border-gray-400 hover:border-gray-400 bg-white dark:bg-gray-900">📽️
        <a :href="getLink(raceInfo.date, raceInfo)" target="_blank">Lien replay</a></span></div>
    <div
      class="mt-5 font-sans table m-auto dark:bg-gray-900 dark:text-gray-400 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600">
      <thead
        class="sticky top-0 py-2 sm:text-sm md:text-md text-center font-bold bg-green-900 dark:bg-green-300 text-white dark:text-green-800">
        <tr>
          <th scope="col" class="w-[60px] m-auto rounded-tl-md">Class.</th>
          <th scope="col" class="w-[140px] text-left">Partants<span v-if="raceInfo.discipline === 'PLAT'" class="italic font-light"> (corde)</span></th>
          <th v-if="raceData[0].horse.discipline !== 'TROT'" scope="col" class="w-[80px] m-auto">Val.</th>
          <th v-if="raceData[0].horse.discipline === 'TROT'" scope="col" class="w-[80px] m-auto">Red km</th>
          <th scope="col" class="w-[120px] text-left">{{ raceInfo.discipline !== 'ATTELE' ? 'Jockey':'Driver' }}</th>
          <th v-if="raceInfo.discipline !== 'ATTELE'" scope="col" class="w-[60px] m-auto">Poids</th>
          <th v-if="raceInfo.discipline !== 'ATTELE' && raceInfo.discipline !== 'MONTE'" scope="col" class="w-[60px] m-auto">Ecart</th>
          <th scope="col" class="w-[60px] m-auto">Cote</th>
          <th scope="col" class="w-[5px] rounded-tr-md"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="runner in raceData" :key="runner.id" class="pb-3 sm:text-[12px] lg:pb-2 text-center font-normal"
          :class="[runningInActualRace(runner.horse.name)]">
          <td class="w-[60px] m-auto">{{ getClassementFromRunner(runner.classement, runner.incident) }}</td>
          <td class="w-[170px] text-left">{{ runner.horse.name }}<span v-if="runner.horse.discipline === 'GALOP'" class="italic font-light"> ({{ runner.corde }})</span>
            <span v-if="runner.def"
              class="text-[8px] text-gray-50 ml-1 px-1 rounded-full align-middle"
              :class="displayDef(runner.def).color">{{ displayDef(runner.def).shortLabel }}</span>
            <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'"
              class="font-bold text-[10px] text-[#dc2626] dark:text-red-400"
              title="Oeillères australiennes">(A)</span>
            <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'"
              class="font-bold text-[12px] text-[#dc2626] dark:text-red-400"
              title="Oeillères pleines"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></span>
          </td>
          <td v-if="runner.horse.discipline !== 'TROT'" class="w-[80px] m-auto">({{ runner.ranking }})</td>
          <td v-if="runner.horse.discipline === 'TROT'" class="w-[80px] m-auto">{{ getRedKm(runner.red_km) }}</td>
          <td class="w-[120px] text-left">{{ runner.rider.name }}</td>
          <td v-if="raceInfo.discipline !== 'ATTELE'" class="w-[60px] m-auto">{{ runner.real_weight ? runner.real_weight / 10 : runner.handicap_weight / 10 }}
          </td>
          <td
            v-if="runner.classement !== 1 && runner.classement !== 101 && raceInfo.discipline != 'MONTE' && raceInfo.discipline != 'ATTELE'"
            class="w-[70px] m-auto">{{
              runner.distance?.short_label }}</td>
          <td class="w-[60px] m-auto">{{ runner.odd_pm }}/1</td>
          <td></td>
        </tr>
      </tbody>
    </div>
  </div>
</template>

<script setup>
import { getClassementFromRunner, displayDef, getRedKm } from '@/services/utils/orderStatCriteriasData.js'
import { getAverageRating } from '@/services/utils/utilsRunnersTools'
import { selectedPastResult, selectedRace } from '@/stores'
import { useRouter } from 'vue-router'
import { ref, watchEffect } from 'vue'

const selectedPastResultState = selectedPastResult();
const raceData = ref(selectedPastResultState.getRaceDetails);
const raceInfo = ref(selectedPastResultState.getRaceInfo);

const selectedRaceState = selectedRace();
const router = useRouter();

const averageRating = ref(getAverageRating(selectedPastResultState.getRaceDetails));

const getLink = (date, race) => {
  return ("https://www.equidia.fr/courses/" +
    new Date(date).toISOString().split("T")[0] +
    "/R" +
    race.num_meeting +
    "/C" +
    race.num_race +
    "#player")
}

const runningInActualRace = (horseName) => {
  if (selectedRaceState.getRunners.find((runner) => runner.horse.name === horseName && runner.horse.name.replace(/\s/g, '-').toLowerCase() === router.currentRoute.value.params.horseSlug)) {
    return 'text-green-500 font-black';
  }
  if (selectedRaceState.getRunners.find((runner) => runner.horse.name === horseName)) {
    return 'font-black';
  }
  else {
    return 'italic';
  }
}

watchEffect(() => {
  raceData.value = selectedPastResultState.getRaceDetails
  raceInfo.value = selectedPastResultState.getRaceInfo
  if (selectedPastResultState.getRaceDetails) {
    averageRating.value = getAverageRating(selectedPastResultState.getRaceDetails);
  }
})

</script>

<style></style>
