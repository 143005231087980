<template>
  <div class="speedometer">
    <div class="card">
      <div class="rating">
        <h2>
          <span class="counter" :data-target="32"></span><sup>%</sup
          ><br />JOCKEY
        </h2>
        <div v-for="i in 49" :key="i" class="blockLevel" :style="getBlockStyle(i)"></div>
      </div>
    </div>
    <div class="card w-[125px] h-[125px] mr-[20px]">
      <div class="rating">
        <h2>
          <span class="counter" :data-target="50"></span><sup>%</sup
          ><br />TERRAIN
        </h2>
        <div v-for="i in 49" :key="i" class="blockLevel" :style="getBlockStyle(i)"></div>
      </div>
    </div>
    <div class="card">
      <div class="rating">
        <h2>
          <span class="counter" :data-target="85"></span><sup>%</sup
          ><br />FORME
        </h2>
        <div v-for="i in 49" :key="i" class="blockLevel" :style="getBlockStyle(i)"></div>
      </div>
    </div>
  </div>
</template>
<style>
.speedometer {
  display: flex;
  justify-content: top;
  align-items: top;
  min-height: 100vh;
}
.card {
  position: relative;
  width: 125px;
  height: 125px;
  margin-right: 20px;
}
.card:nth-child(1) .rating {
  position: relative;
  width: 100%;
  height: 100%;
  animation: slideInFromJockey 0.6s ease-in-out forwards;
}

.card:nth-child(2) .rating {
  position: relative;
  width: 100%;
  height: 100%;
  animation: slideInFromTerrain 0.2s ease-in-out forwards;
}

.card:nth-child(3) .rating {
  position: relative;
  width: 100%;
  height: 100%;
  animation: slideInFromForme 0.4s ease-in-out forwards;
}


@media (max-width: 2000px) {
  @keyframes slideInFromJockey {
    0% {
        top: -500%;
        left: 100%; 
    }
    100% {
        top: 200%;
        left: 400%; 
    }
  };
  @keyframes slideInFromTerrain {
    0% {
        top: -300%;
        left: 800%; 
    }
    50% {
        top: -300%;
        left: 800%; 
    }
    100% {
        top: 550%;
        left: 300%; 
    }
  }
  @keyframes slideInFromForme {
    0% {
        top: 1300%;
        left: 1200%; 
    }
    100% {
        top: 450%;
        left: 750%; 
    }
  }
}

@media (max-width: 1536px) {
  @keyframes slideInFromJockey {
    0% {
        top: 50%;
        left: -500%; 
    }
    100% {
        top: 150%;
        left: 200%; 
    }
  };
  @keyframes slideInFromTerrain {
    0% {
        top: -300%;
        left: 800%; 
    }
    50% {
        top: -300%;
        left: 800%; 
    }
    100% {
        top: 450%;
        left: 00%; 
    }
  }
  @keyframes slideInFromForme {
    0% {
        top: 1300%;
        left: 1200%; 
    }
    100% {
        top: 350%;
        left: 300%; 
    }
  }
}

@media (max-width: 640px) {
  @keyframes slideInFromJockey {
    0% {
        top: 50%;
        left: -500%; 
    }
    100% {
        top: 115%;
        left: 50%; 
    }
  };
  @keyframes slideInFromTerrain {
    0% {
        top: -300%;
        left: 800%; 
    }
    50% {
        top: -300%;
        left: 800%; 
    }
    100% {
        top: 475%;
        left: 100%; 
    }
  }
  @keyframes slideInFromForme {
    0% {
        top: 1300%;
        left: 1200%; 
    }
    100% {
        top: 375%;
        left: -250%; 
    }
  }
}

.card .rating .blockLevel {
  position: absolute;
  width: 2px;
  height: 15px;
  border-radius: 0%;
  background: #900;
  left: 50%;
  transform-origin: 50% 62px;
  opacity: 0;
  animation: animate 0.3s linear forwards;
}
@keyframes animate {
  to {
    opacity: 1;
  }
}

.card:nth-child(1) .rating .blockLevel:nth-child(-n + 18) {
  background: #0f0;
  box-shadow: 0 0 7px #0f0, 0 0 15px #0f0;
}
.card:nth-child(2) .rating .blockLevel:nth-child(-n + 26) {
  background: #0f0;
  box-shadow: 0 0 7px #0f0, 0 0 15px #0f0;
}
.card:nth-child(3) .rating .blockLevel:nth-child(-n + 42) {
  background: #0f0;
  box-shadow: 0 0 7px #0f0, 0 0 15px #0f0;
}
.card .rating h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  line-height: 1em;
  text-shadow: #000 1px 1px 1px;
}

.card .rating h2 span {
  font-size: 2em;
  font-weight: 700;
}

.card .rating h2 sup {
  font-size: 1.5em;
}
</style>
<script>
export default {
  props: {
    name: String,
    target: String,
  },
  data() {
    return {
      counterValueJockey: 0,
      targetValueJockey: 0,
      counterValueTerrain: 0,
      targetValueTerrain: 0,
      counterValueForme: 0,
      targetValueForme: 0,
    };
  },
  mounted() {
    this.targetValueJockey = +this.$el.querySelectorAll('.counter')[0].getAttribute('data-target');
    this.targetValueTerrain = +this.$el.querySelectorAll('.counter')[1].getAttribute('data-target');
    this.targetValueForme = +this.$el.querySelectorAll('.counter')[2].getAttribute('data-target');
    this.animateRating();
    this.animateCounter();
  },
  methods: {
    getBlockStyle(i) {
      return {
        transform: `rotate(${7.2 * i}deg)`,
        animationDelay: `${i / 40}s`,
      };
    },
    animateRating() {
      const rating = this.$el.querySelector('.rating');
      for (let i = 1; i < 50; i++) {
        rating.innerHTML += "<div class='blockLevel'></div>";
      }
    },
    animateCounter() {
      const targetJockey = this.targetValueJockey;
      const targetTerrain = this.targetValueTerrain;
      const targetForme = this.targetValueForme;
      const counters = this.$el.querySelectorAll('.counter')
      const NumberCounter = () => {
        for (let i = 0; i < counters.length; i++) {
          if(i==0){
            if (this.counterValueJockey < targetJockey) {
              this.counterValueJockey = Math.ceil(this.counterValueJockey + 1);
              counters[i].innerHTML = this.counterValueJockey;
              setTimeout(() => {
                NumberCounter();
              }, 10);
            }
          }
          if(i==1){
            if (this.counterValueTerrain < targetTerrain) {
              this.counterValueTerrain = Math.ceil(this.counterValueTerrain + 1);
              counters[i].innerHTML = this.counterValueTerrain;
              setTimeout(() => {
                NumberCounter();
              }, 10);
            }
          }
          if(i==2){
            if (this.counterValueForme < targetForme) {
              this.counterValueForme = Math.ceil(this.counterValueForme + 1);
              counters[i].innerHTML = this.counterValueForme;
              setTimeout(() => {
                NumberCounter();
              }, 10);
            }
          }
        }
        
      };
      NumberCounter();
    },
  },
};
</script>
