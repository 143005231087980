<template>
    <!-- Background image -->
    <div v-if="this.$route.params.article_id===undefined" >
        <div
            class="p-12 text-center relative bg-no-repeat bg-cover bg-center h-[50vh]"
            :style="inlineBgImage"
        >
            <div
            class="absolute top-0 right-0 bottom-0 left-0 w-full h-full bg-fixed"
            style="background-color: rgba(2, 2, 2, 0.2)"
            >
                <div class="flex justify-center items-center h-full">
                    <div class="text-gray-50">
                    <h2 class="font-bold text-4xl mb-4">
                        Le blog de Turfissimo
                    </h2>
                    </div>
                </div>
            </div>
        </div>
        <section class="bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-50 py-1 grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
            <div class="rounded-lg shadow-md bg-white dark:bg-gray-800 dark:text-gray-400 p-5 m-3" v-for="article in articles" :key="article.id" >
                <router-link :to="{ name: 'article', params: { article_id: article.id }}">
                <h1 class="text-xl font-semibold">{{ article.title }}</h1>
                <i class="text-left font-light float-right">le {{ new Date(article.created_at).toLocaleDateString() }}</i><br><hr><br>
                <img :src="article.url_image" alt="" width="150" class="float-left mr-5">
                <p v-html="article.accroche" class="pb-4"></p>
                <p class="text-right font-light">Lire la suite...</p>
                </router-link>
            </div>
        </section>
    </div>
    <div v-else>
        <div
            class="p-12 mx-auto bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-400"
        >
        <article-component></article-component>
        </div>
    </div>
</template>

<script>
import { turfissimo } from '@/services/turfissimo'
import ArticleComponent from '@/components/ArticleComponent.vue'
export default {
    components: { ArticleComponent },
    name: "BlogView",
    computed: {
        inlineBgImage() {
            let bgImage = require('@/assets/capture_blog_page.jpg')

            return {
                backgroundImage: `url("${bgImage}")`,
            }
        },
    },
    methods: {
        async getArticleList(){
            await turfissimo.getBlogArticlesList()
            .then((response) => {
                this.articles = response.data
            })
            .catch((error) => {
                console.log(error.getMessage())
            })  
        }
    },
    async beforeMount() {
        this.articles = this.getArticleList()
    },
    data() {
      return {
        articles: []
      }
    }
}
</script>