<template>
<div v-show="meetings">
  <div
    
    v-for="meeting in meetings"
    :key="meeting.id"
    >
        <div>
            <MeetingCardComponent
                :meeting=meeting
                :key="meeting.id"
            /> 
        </div>
        <Transition name="slide-fade">
        <div v-if="meeting.id == meetingViewState.getSelectedMeeting">  
                <RaceCardComponent 
                    :race=race
                    v-for="race in meeting.races "
                    class="hover:opacity-80     
                    bg-white
                    dark:bg-gray-800 
                    dark:text-gray-300"
                    :class="[race.id === meetingViewState.getSelectedRace && selectedRaceState.getInfos ? 'opacity-100 bg-indigo-500 dark:bg-indigo-900 text-gray-50':'opacity-100']"
                    :key=race.id
                />
        </div>
        </Transition>
    </div>
</div>
</template>

<script setup>
import MeetingCardComponent from '@/components/MeetingCardComponent.vue';
import RaceCardComponent from '@/components/RaceCardComponent.vue';
import { ref, watchEffect } from 'vue';
import { meetingView, selectedRace } from '@/stores'

const meetingViewState = meetingView()
const selectedRaceState = selectedRace()
const meetings = ref(meetingViewState.getMeetings);

watchEffect(() => {
    meetings.value = meetingViewState.getMeetings
})
</script>

<style>

</style>