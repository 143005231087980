<template>
    <!-- Navbar -->
  <nav
    class="navbar navbar-expand-md shadow-md dark:shadow-md dark:shadow-gray-700 py-2 bg-gray-50 dark:bg-gray-900 flex justify-between items-center w-full fixed top-0 right-0 left-0 z-30"
  >
    <div class="sm:px-4 md:px-6">
      <div class="float-left md:pt-0">
      <button
        class="navbar-toggler border-0 py-2 px-2 md:hidden leading-none text-xl bg-transparent text-gray-600 dark:text-gray-400 hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out float-left"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContentO"
        aria-controls="navbarSupportedContentO"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          class="w-5"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
          ></path>
        </svg>
      </button>
      
        <img src="../assets/favicon.png" alt="" class="w-7 pt-1.5 md:w-10 md:pt-0 float-left">
            <router-link
              class="nav-link float-right block p-2 mr-5 font-bold text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-700 focus:text-gray-400 dark:focus:text-gray-300 transition duration-150 ease-in-out"
              to="/"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              >Turfissimo</router-link
            >
      </div>
      <div class="navbar-collapse collapse grow items-center float-left" id="navbarSupportedContentO">
        <ul class="navbar-nav mr-auto flex flex-col md:flex-row">
          <!-- <li class="nav-item">
            <img src="../assets/favicon.png" alt="" class="w-10 float-left">
            <router-link
              class="nav-link float-right block p-2 mr-5 font-bold text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-700 focus:text-gray-400 dark:focus:text-gray-300 transition duration-150 ease-in-out"
              to="/"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              >Turfissimo</router-link
            >
          </li> -->
          <li class="nav-item">
            <router-link
              class="nav-link block p-2 text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-700 focus:text-gray-400 dark:focus:text-gray-300 transition duration-150 ease-in-out"
              :to="getLink"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              >Tableau de bord</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link block p-2 text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-700 focus:text-gray-400 dark:focus:text-gray-300 transition duration-150 ease-in-out"
              to="/blog"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              >Blog</router-link
            >
          </li>
          <!-- <li class="nav-item">
            <router-link
              class="nav-link block p-2 text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-700 focus:text-gray-400 dark:focus:text-gray-300 transition duration-150 ease-in-out"
              to="/strategy"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              >Stratégies</router-link
            >
          </li> -->
          <!-- <li class="nav-item">
            <span v-text="test"></span>
          </li> -->
          <li class="nav-item">
            <button
              class="nav-link block p-2 text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-700 focus:text-gray-400 transition duration-150 ease-in-out"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              :title="appDatasState.getDarkMode ? 'Light mode':'Dark mode'"
              @click="changeMode"
              ><font-awesome-icon :icon="appDatasState.getDarkMode ? 'fa-solid fa-sun':'fa-solid fa-moon'"/></button
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- Right elements -->
    <!-- <div class="flex items-center relative pr-4">
      <a class="text-gray-500 hover:text-gray-700 focus:text-gray-700 mr-4" href="#">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-cart"
          class="w-4" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512">
          <path fill="currentColor"
            d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z">
          </path>
        </svg>
      </a>
      <div v-if="!isDisconnected" class="dropdown relative">
        <router-link to="/strategy/selection">
          <a class="
            text-gray-500
            hover:text-gray-700
            focus:text-gray-700
            mr-4
            dropdown-toggle
            hidden-arrow
            flex items-center
            ">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bell"
              class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor"
                d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z">
              </path>
            </svg>
            <span class="text-white bg-red-700 absolute rounded-full text-xs -mt-2.5 ml-2 py-0 px-1.5">{{ selectedHorseCount }}</span>
          </a>
        </router-link>
        <ul class="
          dropdown-menu
          min-w-max
          absolute
          hidden
          bg-white
          text-base
          z-50
          float-left
          py-2
          list-none
          text-left
          rounded-lg
          shadow-lg
          mt-1
          m-0
          bg-clip-padding
          border-none
          left-auto
          right-0
        " aria-labelledby="dropdownMenuButton1">
          <li>
            <a class="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
            " href="#">Action</a>
          </li>
          <li>
            <a class="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
            " href="#">Another action</a>
          </li>
          <li>
            <a class="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
            " href="#">Something else here</a>
          </li> 
        </ul>
      </div>
      <div class="dropdown relative">
        <a class="dropdown-toggle flex items-center hidden-arrow" href="#" id="dropdownMenuButton2" role="button"
          data-bs-toggle="dropdown" aria-expanded="false">
          <font-awesome-icon class="dark:text-gray-500 w-[25px] dark:hover:text-gray-700
              focus:text-gray-700" icon="fa-solid fa-user" />
        </a>
        <ul class="
        dropdown-menu
        min-w-max
        absolute
        hidden
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded-lg
        shadow-lg
        mt-1
        m-0
        bg-clip-padding
        border-none
        left-auto
        right-0
      " aria-labelledby="dropdownMenuButton2">
          <li v-if="isDisconnected">
            <router-link 
            class="
            dropdown-item
            text-sm
            py-2
            px-4
            font-normal
            block
            w-full
            whitespace-nowrap
            bg-transparent
            text-gray-700
            hover:bg-gray-100
            " :to="{ name: 'signin' }">Se connecter</router-link>
          </li>
          <li v-else
          class="
            dropdown-item
            text-sm
            py-2
            px-4
            font-normal
            block
            w-full
            whitespace-nowrap
            bg-transparent
            text-gray-700
            hover:bg-gray-100
            cursor-pointer
            "
            @click="disconnect()"
          >Se déconnecter</li>
          <li>
            <router-link class="
            dropdown-item
            text-sm
            py-2
            px-4
            font-normal
            block
            w-full
            whitespace-nowrap
            bg-transparent
            text-gray-700
            hover:bg-gray-100
          " :to="{ name: 'register' }">S'enregistrer</router-link>
          </li>
        </ul>
      </div>
    </div> -->
  <!-- Right elements -->
  </nav>
  <!-- Navbar -->
  
</template>

<script setup>
//import { storeToRefs } from 'pinia'
//import { raceStore } from '../stores/index'
import { appDatas } from '@/stores'
import { computed, onBeforeMount } from 'vue'
//import { turfissimo } from "@/services/turfissimo";

const appDatasState = appDatas();
//const strategiesState = strategies();

//const selectedHorseCount = 12

const getLink = computed(() => {
  return '/dashboard/'+new Date().toISOString().slice(0,10)
})

onBeforeMount(() => {
  appDatasState.setUserToken(localStorage.getItem('turfissimo_access_token'))
  appDatasState.setUserInfos(localStorage.getItem('turfissimo_user'))
});

// const { userToken } = storeToRefs(appDatasState)
// const isDisconnected = computed(() => {
//   return userToken.value === null
//   //return localStorage.getItem('turfissimo_access_token') === null
// })

//const open = false;
//const darkMode = ref(appDatasState.getDarkMode)
// function disconnect(){
//   localStorage.removeItem('turfissimo_access_token')
//   localStorage.removeItem('turfissimo_user')
//   appDatasState.disconnect()
//   strategiesState.resetStrategies()
//   turfissimo.logout()
// }

function changeMode(){
  appDatasState.switchDarkMode();
}
</script>

<style>

</style>