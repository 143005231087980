<template>
    <div class="mx-auto">
      <img src="../assets/favicon.png" alt="" width=80 class="mx-auto mb-3 animate-spin">
      <button type="button" class="bg-indigo-500 w-[200px] h-10 rounded-full" disabled>
        <div class="text-white font-semibold mx-auto" >
          <span class="h-7 w-7 font-semibold" viewBox="0 0 26 26">
            <font-awesome-icon class="animate-spin" icon="fa-solid fa-spinner" />
          </span>
          <span>Loading...</span>
        </div>
      </button>
    </div>
</template>

<script setup>
</script>