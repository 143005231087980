<template>
  <div
    class="relative rounded-md border-2 dark:border-gray-800 border-gray-200 shadow-md pb-12"
  >
    <h2 class="text-align text-xl font-semibold border-gray-200 dark:border-gray-800 border-b-2 pb-3">{{ props.strategy.label }}
      <span class="float-right">{{ props.strategy.mini_total }}pts</span>
    </h2>
    <div
      v-for="indicateur in props.strategy.statistic_criterias"
      :key="indicateur.statistic_criteria.label"
      class="relative flex flex-row items-center"
    >
      <div class="basis-1/4">
        <div>
          <Doughnut
            :options="getOption()"
            :data="getData(indicateur)"
            :width=45 
            :height=45
            class="w-10"
          />
          <span class="w-10 absolute top-[22px] left-[6px] mx-auto text-xs text-center font-bold block m-auto">{{ indicateur.mini_count }}%</span> 
        </div>
      </div>
      <div class="text-md pt-2 ml-2 basis-1/2">{{ indicateur.statistic_criteria.label }}</div>
      <div class="pt-2 basis-1/4">{{ indicateur.ratio }}pt(s)</div>
    </div>
    <div class="absolute bottom-2 right-3 ">
      <button v-if="props.strategy.user_id === 1" @click="updateStrategy(props.strategy.id)" class="px-2 rounded-md" title="Modifier la stratégie"><font-awesome-icon icon="fa-solid fa-gear" /></button>
      <button @click="getSelection(props.strategy.id)" class="px-2 rounded-md" title="Voir les chevaux du jour"><font-awesome-icon icon="fa-solid fa-eye" /></button>
    </div>
  </div>
</template>

<script setup>
import { Doughnut } from "vue-chartjs";
import { ref, defineProps } from "vue";
import { turfissimo } from "@/services/turfissimo";
import { useRouter } from "vue-router";

const props = defineProps(['strategy'])
const router = useRouter()

function getOption() {
  return ref({
    responsive: false,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
      animateScale: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "15",
        align: "align",
        fullSize: false,
      }
    },
  });
}

function getData(strategy) {
      let total = 100;
      let count = strategy.mini_count;
      let data = [0, 0, 0, 0];
      if (strategy.type_label === "gagnant") {
        data[0] = count;
      }
      if (strategy.type_label === "place") {
        data[1] = count;
      }
      if (strategy.type_label === "itm") {
        data[2] = count;
      }
      data[3] = total - count;
      return {
        //labels: ["GAG", "PLA", "ITM", "NPL"],
        datasets: [
          {
            backgroundColor: ["#166534", "#84cc16", "#fb923c", "#dc2626"],
            borderColor: "#222",
            cutout: "70%",
            animation: {
              animateRotate: false,
              animateScale: false,
            },
            data: data,
          },
        ],
      };
}

function getSelection(id) {
  const response = turfissimo.getStrategySelectionByDate(id, new Date().toISOString().slice(0,10))
  console.log(response);
}
 
function updateStrategy(id) {
  console.log(id)
  router.push({ name: 'StrategyUpdate', params: { id: id } })
}
</script>

<style>
</style>