<template>
  <div class="dark:bg-gray-800 flex-row pl-0 md:pl-0 font-sans">
    <div class="text-sm md:text-md ml-3 py-2">
        <div>Jockey/Driver : {{ runnerInfos.rider.name }}</div>
        <div>Entraineur : {{ runnerInfos.trainer.name }}</div>
        <div>Musique : {{ runnerInfos.musique }}</div>
        <div>Origine : {{ runnerInfos.horse.sire_name }} / {{ runnerInfos.horse.dam_name }} par {{ runnerInfos.horse.sire_dam_name }}</div>
    </div>
    <div class="relative p-0 md:p-2 mt-3 sm:mx-auto md:mx-0 w-full md:w-full">
      <div class="pb-8">
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-map-location-dot" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-arrows-left-right" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-user" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-gear" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-trophy" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-sack-dollar" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-weight-scale" />
      <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-calendar" />
      </div>
      <div class="block absolute top-8 left-0 center p-0 md:p-2">          
        <div v-for="index in 9" :key="index" class="w-16 pl-2.5 text-[10px] float-left pb-11">
          <DoughnutComponent
            :chartOptions="options"
            :chartData="getData(index-1)"
            :datasetIdKey="index"
            :key="index"
            updateMode="show"
            :width=45
            :height=45
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import DoughnutComponent from '../DoughnutComponent.vue'
    export default {
        components: { DoughnutComponent },
        name:'InfoRunnerComponent',
        props: {
            runnerInfos: Object,
            stat: Array,
            height: Number,
            width: Number
        },
        setup(props) {
          const getData = (index) => { 
            return {
              labels: ['GAG', 'PLA', 'ITM', 'NPL'],
              datasets: [
                {
                  backgroundColor: ['#166534', '#84cc16', '#fb923c', '#dc2626'],
                  borderColor: '#222',
                  cutout: '70%',
                  animation: {
                    animateRotate:true,
                    animateScale:true
                  }, 
                  data: props.stat[index] 
                }
              ]
            }
          }
          const options = ref ({
            responsive: false,
            maintainAspectRatio: false,
            animation: {
              duration:350,
              delay:400,
              easing:'easeOutCirc'
            },
            plugins: {
                legend: {
                    display: false
                },
                title:{
                  display: false,
                  text: '5',
                  align: 'align',
                  fullSize: false 
                }
            }
          })
          return {
            getData,
            options
          }
        }
    }
</script>

<style>

</style>