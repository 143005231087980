<template>
  <div class="p-2 pb-20 sm:pb-0 md:max-w-md mx-auto">
    <div class="rounded-lg shadow-xl bg-white  dark:bg-gray-800 dark:text-gray-50">
        <a href="#!">
        <img class="rounded-t-lg" :src="image.length ? require(`../assets/${image}`) : '' " alt=""/>
        </a>
        <div class="p-6">
        <h5 class="text-gray-900 dark:bg-gray-800 dark:text-gray-300 text-2xl font-medium">{{ title }}</h5>
        <p v-if="description.length > 0" class="text-gray-700 dark:bg-gray-800 dark:text-gray-50 text-base my-4">
            {{ description }}
        </p>
        <router-link :to="{ name: link, params: params }">
        <button v-if="buttonName.length > 0" type="button" class=" inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out">{{ buttonName }}</button>
        </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "HomeCardComponent",
    props: {
        title: String,
        description: String,
        image: String,
        link: String,
        params: Object,
        buttonName: String,
        buttonHref: String
    },
    computed: {
      setImage: function() {
        return "../assets/"+this.image
      }
    }
}
</script>

<style>

</style>