<template>
  <div class="
  flex flex-col relative top-[2em]
  min-h-screen
  bg-gray-100 dark:bg-gray-800
  text-gray-800 dark:text-gray-500
  ">
    <h1>{{ raceName.charAt(0).toUpperCase() + raceName.slice(1).replaceAll('-', ' ') }}</h1>
    <table>
        <thead>
            <tr>
                <th>Classement</th>
                <th>Cheval</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="runner in raceData" :key="runner.id">
                <td>{{ getIncidentInfo(runner.classement, runner.incident) }}</td>
                <td><router-link :to="{ name: 'FicheCheval',params: { name:runner.horse.name, runnerId: runner.horse.id, date: new Date() }}" target="_blank">{{ runner.horse.name }}</router-link></td>
                <td>{{ runner.rider.name }}</td>
                <td v-if="runner.classement !== 1 && runner.classement !== 101">{{ runner.distance.short_label }}</td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import { turfissimo } from '@/services/turfissimo'
import { getClassementFromRunner } from '@/services/utils/orderStatCriteriasData.js'
export default {
    name: 'RaceResultsComponent',
    props: {
        raceId: [String, Number],
        raceName: String
    },
    setup() {
      const getIncidentInfo = (classement, incident) => {
        return getClassementFromRunner(classement, incident)
      }

      return {
        getIncidentInfo
      }
    },
    async mounted() {
        const response = await turfissimo.getRaceResult(this.raceId)
        this.raceData = response.data.runners.sort((firstItem, secondItem) => firstItem.classement - secondItem.classement)
    },
    data() {
      return {
        raceData: null
      }
    }
}
</script>

<style>

</style>