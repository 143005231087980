<template>
<div>
    <div class="
        grid 
        grid-flow-col
        gap-2
        mb-2
        mx-2
        shadow-md
        rounded-md
        cursor-pointer
        hover:opacity-80 
        bg-white
        dark:bg-gray-800"
        @click="emitMeetingId(props.meeting.id)">
        <div class="dark:bg-green-300 dark:text-green-700 bg-green-900 grid-cols-2 rounded-l-md shadow-md text-gray-50 sm:text-xl md:text-2xl lg:text-2xl font-bold text-center p-2"><h1 class="">R{{ props.meeting.races[0].num_meeting }}</h1></div>
        <div class="w-60">
            <div class="col-span-2 rounded-r-md text-gray-900 dark:bg-gray-800 dark:text-gray-400 sm:text-xl md:text-2xl lg:text-2xl text-start relative font-bold">
                <h1>{{ props.meeting.racecourse.short_label }}</h1>
            </div>
            <div class="col-span-3 rounded-r-md text-gray-700 dark:bg-gray-800 dark:text-gray-400 text-sm">
                <p>{{ props.meeting.races_count }} courses</p>
            </div>
        </div>            
    </div>
</div>
</template>

<script setup>
import { defineProps } from 'vue';
import { meetingView, selectedRace } from '@/stores';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
    meeting: Object
})

const emitMeetingId = (id) => {
    if(meetingView().getSelectedMeeting !== id){
        selectedRace().resetState()
        meetingView().loadSelectedMeeting(id)
    } else {
        selectedRace().resetState()
        meetingView().resetSelectedMeeting()
    }
    const date = router.currentRoute.value.params.date
    router.push({ name: 'Dashboard', params: { date: date }})
}
</script>

<!-- <script>
export default {
    name: "MeetingCardComponent",
    props: {
        meeting: Object,
    },
    emits: [
        'last-meeting-id-click'
    ],
    setup(props, context){
        const emitMeetingId = (id) => {
            context.emit('last-meeting-id-click', {id:id})
        }

        return {
            emitMeetingId
        }
    }
}
</script> -->

<style>

</style>