<template>
<div class="flex ml-1 text-[12px]">
  <div
    :style="{
      backgroundColor: modelValue ? colorOK : colorKO,
      width: width,
      height: height,
    }"
    class="vue-toggle"
    @click="this.$emit('update:model-value', !modelValue)"
  >
  
    <input type="checkbox" :checked="modelValue" />
    <div
      :style="{ transform: `translateX(${modelValue ? 100 : 0}%)` }"
      class="toggler"
    />
  </div>
  <div class="ml-2">
    <div v-if="!modelValue">{{ option1 }}</div>
    <div v-if="modelValue">{{ option2 }}</div>
  </div>
</div>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "update:model-value",
  },
  props: {
    option1: {
      type: String,
    },
    option2: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default:'partant',
    },
    colorKO: {
      type: String,
      default: "#0d9488",
    },
    colorOK: {
      type: String,
      default: "#ef4444",
    },
    width: {
      type: String,
      default: "2rem",
    },
    height: {
      type: String,
      default: "1rem",
    },
  }
};
</script>

<style scoped>
.toggler {
  transition: all 0.2s;
}

.vue-toggle {
  border-radius: 9999px;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.vue-toggle input {
  display: none;
}

.vue-toggle .toggler {
  width: 50%;
  height: 100%;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
</style>