<template>
  <div
    id="explanation"
    class="md:flex md:flex-row md:flex-wrap overflow-hidden pt-6 min-h-screen h-full dark:bg-gray-900 dark:text-gray-500"
  >
    <div class="md:w-[45%] sm:w-[100%] md:m-6">
      <!-- Background image -->
      <div
        class="sm:px-12 md:px-12 text-center relative overflow-hidden bg-no-repeat bg-cover bg-center h-screen"
        :style="inlineBgImageExplanation"
      ></div>
      <!-- <img src="@/assets/racecard-bg.png" alt=""> -->
    </div>
    <div class="md:w-[50%] sm:w-[95%] explanation">
      <h1 class="font-bold text-3xl md:text-5xl mt-10 mb-10">💡 Les indicateurs </h1>
      <div class="pb-5 pl-5">
        <p class="italic px:5">
          "Pour chaque course de la journée, retrouvez la synthèse des
          indicateurs de chaque partant."
        </p>
      </div>
      <div class="animationExplanation">
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class=" text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-map-location-dot"
            />
          </div>
          <div>
            <p>
              <strong>Hippodrome : </strong> La réussite d'un partant sur un hippodrome spécifique est 🔑 cruciale pour les courses hippiques 🏇.
               📈 Cela indique une adaptation optimale aux conditions de piste, donnant un avantage potentiel pour la victoire 🏆💪.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-arrows-left-right"
            />
          </div>
          <div>
            <p>
              <strong>Distance : </strong> Analyser les performances passées d'un partant sur une distance donnée 🏁 permet aux parieurs d'anticiper son potentiel d'adaptation, 
              ce qui peut se traduire par une victoire fracassante ou une performance décevante selon sa préférence de course 🏇🏆.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[23px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-cloud-sun-rain"
            />
          </div>
          <div>
            <p>
              <strong>Terrain : </strong>Le type de terrain sur lequel se déroule une course peut faire toute la différence dans le résultat.🌧️🌞 
              En examinant le passé d'un partant sur différents types de terrains, les parieurs peuvent prédire sa capacité à s'adapter aux conditions actuelles 🌱🏇.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-user"
            />
          </div>
          <div>
            <p>
              <strong>Jockey/Driver : </strong>Certains jockeys ont une excellente synergie avec certains chevaux 🤝🏇. 
              En étudiant le passé de l'association entre un partant et son jockey, 
              les parieurs peuvent déterminer si cette collaboration peut être décisive pour la course à venir, et ainsi placer leurs paris en toute confiance 💫🏆.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-gear"
            />
          </div>
          <div>
            <p>
              <strong>Artifices : </strong>Les artifices utilisés pendant la course ⚙️🏇, comme les œillères ou les fers, peuvent avoir un impact significatif sur la performance. 
              Certains chevaux peuvent montrer une nette amélioration en utilisant certaines aides, tandis que d'autres peuvent être perturbés par ces modifications 📊🏆.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-trophy"
            />
          </div>
          <div>
            <p>
              <strong>Discipline : </strong>Chaque discipline de course hippique 🏇🏅 (attelé, monté, haies, steeple...) demande des compétences spécifiques. 
              Un cheval qui excelle dans une discipline peut être moins performant dans une autre 🎯🏆.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-sack-dollar"
            />
          </div>
          <div>
            <p>
              <strong>Allocations : </strong>Le niveau d'allocation 💰🏇, c'est le montant du prix à gagner, qui détermine la catégorie de la course. 
              En prenant en compte le passé d'un partant dans des courses de niveaux d'allocation similaires, 
              les parieurs peuvent évaluer si le cheval est dans "sa catégorie" 💵🏆.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-weight-scale"
            />
          </div>
          <div>
            <p>
              <strong>Valeur Handicap : </strong>La valeur handicap d'un cheval 📉🏇 est un indice attribué en fonction de ses performances passées. 
              Avec l'évolution de la valeur handicap d'un partant, on peut déterminer si le cheval est à une valeur compétitive, 
              ce qui peut avoir un impact sur ses chances de succès 📈🏆.
            </p>
          </div>
        </div>
        <div class="item flex flex-cols flex-nowrap items-center">
          <div>
            <font-awesome-icon
              class="text-[30px] p-2 mr-10 rounded-full"
              icon="fa-solid fa-calendar"
            />
          </div>
          <div>
            <p>
              <strong>Forme Saisonnière : </strong>La forme saisonnière d'un cheval fait référence à ses performances à différentes périodes de l'année 🍂❄️🌸 . 
              Certains chevaux sont plus performants pendant certaines saisons, tandis que d'autres peuvent se montrer réguliers tout au long de l'année 📅🏆.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.animationExplanation {
  position: relative;
  width: 100%;
  height: 70%;
}
.item {
  position: relative;
  margin: 15px;
}
 .item:nth-child(1).animated {
  animation: explanationFall 1s ease-in-out forwards;
}
.item:nth-child(2).animated {
  animation: explanationFall 1.05s ease-in-out forwards;
}
.item:nth-child(3).animated {
  animation: explanationFall 1.1s ease-in-out forwards;
}
.item:nth-child(4).animated {
  animation: explanationFall 1.15s ease-in-out forwards;
}
.item:nth-child(5).animated {
  animation: explanationFall 1.2s ease-in-out forwards;
}
.item:nth-child(6).animated {
  animation: explanationFall 1.25s ease-in-out forwards;
}
.item:nth-child(7).animated {
  animation: explanationFall 1.3s ease-in-out forwards;
}
.item:nth-child(8).animated {
  animation: explanationFall 1.35s ease-in-out forwards;
}
.item:nth-child(9).animated {
  animation: explanationFall 1.4s ease-in-out forwards;
} 

@keyframes explanationFall {
  0% {
    top: 0%;
    left: 100%;
  }
  100% {
    top: 0%;
    left: 0%;
  }
}
</style>
<script setup>
import { appDatas } from "@/stores";
import { computed, ref } from "vue";

const appDatasState = appDatas();

const inlineBgImageExplanation = computed(() => {
    const bgImage = ref(appDatasState.getDarkMode != true ? require("@/assets/explanation-dark-portrait.png") : require("@/assets/explanation-light-portrait.png"));
    const percentSize = screen.height > screen.width ? '80%' : '45%'
    return {
      backgroundImage: `url("${bgImage.value}")`,
      backgroundSize: `${percentSize}`,
    };
})
</script>