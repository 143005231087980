<template>
  <div class="container">
    <div
    :class="bgColorRef"
    class="px-5 mb-1 w-8 justify-center rounded-full font-semibold text-sm flex align-center cursor-pointer active:bg-gray-300 transition duration-300 ease"
    >
        {{ referenceOdd }}
    </div>
    <span
    :class="bgColorFin"
    class="px-5 w-8 justify-center rounded-full font-semibold text-sm flex align-center cursor-pointer active:bg-gray-300 transition duration-300 ease">
        {{ lastOdd }}
    </span>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
    name:"OddPmuComponent",
    props: {
        'referenceOdd': Number,
        'lastOdd': Number
    },
    setup() {
      const bgColorRef = ref ('bg-blue-900 shadow-blue-900/50 dark:bg-blue-300 text-blue-100 dark:text-blue-800')
      const bgColorFin = ref ('bg-blue-900 shadow-blue-900/50 dark:bg-blue-300 text-blue-100 dark:text-blue-800')

      const setBgColor = async (props) => {
        if(props.referenceOdd > 15) { bgColorRef.value = 'bg-red-600 dark:bg-red-300 text-gray-100 dark:text-gray-800' }
        if(props.referenceOdd > 7 && props.referenceOdd<= 15) { bgColorRef.value =  'bg-orange-600 dark:bg-orange-300 text-gray-100 dark:text-gray-800' }
        if(props.referenceOdd > 3 && props.referenceOdd<= 7) { bgColorRef.value =  'bg-lime-600 dark:bg-lime-300 text-gray-100 dark:text-gray-800' }
        if(props.referenceOdd <= 3) { bgColorRef.value =  'bg-green-600 dark:bg-green-300 text-gray-100 dark:text-gray-800' }

        if(props.lastOdd > 15) { bgColorFin.value = 'bg-red-600 dark:bg-red-300 text-gray-100 dark:text-gray-800' }
        if(props.lastOdd > 7 && props.lastOdd<= 15) { bgColorFin.value =  'bg-orange-600 dark:bg-orange-300 text-gray-100 dark:text-gray-800' }
        if(props.lastOdd > 3 && props.lastOdd<= 7) { bgColorFin.value =  'bg-lime-600 dark:bg-lime-300 text-gray-100 dark:text-gray-800' }
        if(props.lastOdd <= 3) { bgColorFin.value =  'bg-green-600 dark:bg-green-300 text-gray-100 dark:text-gray-800' }
      }

      //setBgColor(props)

      return {
        bgColorRef,
        bgColorFin,
        setBgColor
      }
    }
}
</script>

<style>

</style>