<template>
<div>
  <div class="dark:bg-gray-800 bg-gray-200 md:h-full flex flex-row">
    <div class="">
      
      <SelectorRunnersComponent
        class="dark:text-gray-400 text-gray-600 dark:bg-gray-800 bg-gray-200 ml-2 w-[calc(100vw_-_20px)] md:ml-5 md:min-w-[400px] md:w-1/2 mx-auto"
        :name=props.name
        :runners=props.runners
        @changeSelection="changeSelection"
      ></SelectorRunnersComponent>
      <InfoRunnerComponent
        :runnerInfos="getRunnerInfo()"
        :stat="getRunnerStatInfo()"
      ></InfoRunnerComponent>
      <PastResultsComponent 
        class="dark:text-gray-400 text-gray-600 pr-0 dark:bg-gray-800 bg-gray-200 hidden md:block"
        @get-details="getDetails"
      ></PastResultsComponent>
      <!-- Début Bloc PastResultMobile -->
      <div v-if="selectedPastResultState.getRaceDetails === null" class="block md:hidden text-xs font-sans">
        <PastResultsMobileComponent 
          :runnerData=runnerData
          :selectedRunnerState=selectedRunnerState
          :getDetails="getDetails"
        ></PastResultsMobileComponent>  
      </div>
      <!-- Fin Bloc PastResultMobile -->
    </div>
    <div v-if="selectedPastResultState.getRaceDetails !== null"  class="hidden md:block text-xs font-sans">
      <PastResultDetailsComponent
      class="dark:text-gray-400 text-gray-600 pr-0 dark:bg-gray-800 bg-gray-200 py-4"
      ></PastResultDetailsComponent>
      
    </div>
    <button
        type="button"
        @click="review"
        title="fermer"
        class="absolute right-1 rounded-full float-right pr-4 pt-2 mt-1 z-4 hidden md:block"
      >
        <font-awesome-icon
          class="dark:text-gray-600 text-grey-800 text-[24px]"
          icon="fa-solid fa-circle-xmark"
        />
      </button>
  </div>
  <div v-if="selectedPastResultState.getRaceDetails !== null"  class="block md:hidden text-xs font-sans">
    <PastResultDetailsComponent
    class="dark:text-gray-400 text-gray-600 pr-0 dark:bg-gray-800 bg-gray-200 py-4"
    ></PastResultDetailsComponent>
    
  </div>
</div>
</template>

<script setup>
import PastResultsComponent from "../components/RunnerPastResults/PastResultsComponent.vue";
import PastResultsMobileComponent from "@/components/RunnerPastResults/PastResultsMobileComponent.vue";
import SelectorRunnersComponent from "../components/RunnerPastResults/SelectorRunnersComponent.vue";
import PastResultDetailsComponent from "@/components/RunnerPastResults/PastResultDetailsComponent.vue";
import InfoRunnerComponent from "../components/RunnerPastResults/InfoRunnerComponent.vue";
import { turfissimo } from "@/services/turfissimo";
import { selectedPastResult, selectedRace, selectedRunner } from '@/stores';
import { onMounted, ref, defineEmits, defineProps, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const params = ref(router.currentRoute.value.params);

const emits = defineEmits(['resetPastResult'])

const props = defineProps({
  name: String,
  numSelected: [Number, String],
  runnerId: [Number, String],
  statCriterias: Array,
  date: [String, Date],
  runners: Object,
});

const selectedRaceState = selectedRace();
const race = ref(selectedRaceState.getInfos);

const selectedRunnerState = selectedRunner();
const runnerData = ref(selectedRunnerState.getPastResults);

const selectedPastResultState = selectedPastResult();

const runnerInfo = ref(props.runners[props.numSelected - 1]);

onMounted(async() => {
  const element = document.getElementById("PastResult");
  element.scrollTop = 0

  // const url =
  //   "https://turfissimo.com/api/runners/"
  //   + props.runnerId
  //   + "/" 
  //   + props.date 
  //   + "?include=race.meeting.racecourse,horse,rider";
  const response = await turfissimo.getPastResults(props.runnerId, props.date);
  selectedRunnerState.loadPastResults(response.data);

  if(params.value.raceId){
    const response = await turfissimo.getRaceResult(params.value.raceId);
    selectedPastResultState.loadRaceDetails(response.data.runners.sort(
      (firstItem, secondItem) => firstItem.classement - secondItem.classement
    ));
  }
});

const review = () => {
  // selectedPastResultState.resetState();
  // selectedRunnerState.resetState();
  // router.push({
  //   name: "Racecard",
  //   params: {
  //     R: race.value.num_meeting,
  //     C: race.value.num_race,
  //     slug: race.value.name.replace(/\s/g, "-").toLowerCase(),
  //   },
  // });
  
  emits("resetPastResult");
}

const changeSelection = (async(id) => {
  selectedPastResultState.resetState();
  selectedRunnerState.resetState();
  runnerInfo.value = null;  
  runnerInfo.value = props.runners.find((runner) => runner.horse_id === id);
  // const url =
  //   "https://turfissimo.com/api/runners/"
  //   + id
  //   + "/"
  //   + props.date
  //   + "?include=race.meeting.racecourse,horse,rider";
  const response = await turfissimo.getPastResults(id, props.date);
  selectedRunnerState.loadPastResults(response.data)
})

const getDetails = async (race) => {
  selectedPastResultState.resetState();
  router.push({
    name: "RunnerPastRaceDetails",
    params: { raceId: race.id },
  });
  selectedPastResultState.loadRaceInfo(race);
  const response = await turfissimo.getRaceResult(race.id);
  selectedPastResultState.loadRaceDetails(response.data.runners.sort(
    (firstItem, secondItem) => firstItem.classement - secondItem.classement
  ));
}

const getRunnerInfo = () => {
  return runnerInfo.value
}

const getRunnerStatInfo = () => {
  return props.statCriterias[parseInt(runnerInfo.value.number) - 1];
}

watchEffect(() => {
  race.value = selectedRaceState.getInfos;
  runnerData.value = selectedRunnerState.getPastResults;
  params.value = router.currentRoute.value.params;
  if(!params.value.raceId){
    selectedPastResultState.resetState();
  }
})


</script>

<style>
</style>