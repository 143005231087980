<template>
  <!-- FORMAT LG > 1500px -->

  <div v-if="getFormat() === 'lg'" class="ml-auto mr-auto">
    <div v-if="runners?.length === 0" class="pt-30 flex justify-center items-center place-items-center">
      <!-- <LoaderComponent/> -->
    </div>
    <div v-else>
      <ToggleButtonComponent class="pl-2 pb-2" v-model="showFinalResult" option1="Partants" option2="Arrivée" />
      <div class="flex flex-row">
        <div v-if="race.discipline != 'ATTELE' && race.discipline != 'MONTE'" class="ml-2  font-medium text-sm">📈 Valeur moyenne du lot
        : {{ averageRating }}</div>
        <div v-if="race.validated == 1" class="px-2 flex-col font-medium text-sm"><span
          class="pr-1 rounded-full border-solid border-[1px] hover:text-gray-600 border-gray-600 hover:border-gray-400 bg-white dark:bg-gray-900">📽️
          <a :href="getLink()" target="_blank">Lien replay</a></span></div>
      </div>
      
      <div v-if="!showFinalResult && runners?.length > 0">
        <div class="table w-full">
          <thead>
            <tr class="shadow-sm shadow-gray-300 dark:shadow-gray-600">
              <th class="w-12">N°</th>
              <th class="w-16"></th>
              <th class="text-left w-48">
                <div class="text-[12px]">Cheval</div>
                <div class="text-[8px]">sexe/âge</div>
              </th>
              <th class="text-center">
                <div class="text-[10px]">Ref</div>
                <div class="text-[10px]">Fin</div>
              </th>
              <th class="text-left">
                <div class="text-[10px]">Jockey</div>
                <div class="text-[10px]">Entraineur</div>
                <div class="text-[8px]">Corde/Dist.</div>
              </th>
              <th class="text-left pl-2">
                <div class="text-[12px]">Musique</div>
              </th>
              <th class="text-left"
                :title="'Réussite ' + meetingViewState.getMeetings.find((element) => element.id === meetingViewState.getSelectedMeeting).racecourse.short_label">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-map-location-dot" />
              </th>
              <th class="text-left" :title="'Réussite ' + race.distance + 'm +/- 100m'">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-arrows-left-right" />
              </th>
              <th v-if="race.discipline != 'ATTELE' && race.discipline != 'MONTE'" class="text-left"
                :title="'Réussite ' + race.penetrometer_label">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
              </th>
              <th class="text-left" :title="'Réussite avec le jockey du jour'">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-user" />
              </th>
              <th class="text-left" :title="'Réussite avec les artifices du jour'">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-gear" />
              </th>
              <th class="text-left" :title="'Réussite dans la discipline du jour'">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-trophy" />
              </th>
              <th class="text-left" :title="'Réussite dans les courses avec l\'allocation du jour +/- 10%'">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-sack-dollar" />
              </th>
              <th v-if="race.discipline != 'ATTELE' && race.discipline != 'MONTE' && race.category.includes('HANDICAP')"
                class="text-left" :title="'Réussite avec la valeur handicap du jour +/- 0.5kg'">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-weight-scale" />
              </th>
              <th class="text-left" :title="'Réussite à cette période de l\'année +/- 15 jours'">
                <font-awesome-icon class="text-[24px]" icon="fa-solid fa-calendar" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(runner, ind) in runners.sort(byNumber)" :key=runner.id
              @click="closeRaceRunnerCard(runner, runners.sort(byNumber))"
              class="w-full shadow-sm shadow-gray-200 dark:shadow-gray-800 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700">
              <td class="pt-0.5 pr-2 py-4 text-3xl text-center font-bold">{{ runner.number }}</td>
              <td>
                <div class="w-[50px] h-[50px] mr-2 dark:bg-gray-800 bg-gray-300 rounded-full"><img :src="runner.url_silks"
                    class="w-[36px] mx-auto block pt-0.5 text-align" alt=""></div>
              </td>
              <td class="pt-0 ml-3 text-[12px] font-medium">
                <span class="cursor-pointer align-baseline">{{ runner.horse.name }}</span>
                <span v-if=userHorseSelection(runner.horse.id) class="ml-1"><font-awesome-icon class="text-yellow-500" icon="fa-solid fa-star" /></span>
                <span v-else class="ml-1"><font-awesome-icon icon="fa-regular fa-star" /></span>
                <span v-if="runner.def" :class="displayDef(runner.def).color"
                  class="text-[8px] text-gray-50 ml-3 px-1 rounded-full align-middle">{{ displayDef(runner.def).shortLabel
                  }}</span>

                <!-- <router-link :to="{ name: 'FicheCheval',params: { name:runner.horse.name, runnerId: runner.horse.id, date: runner.date }}" target="_blank" class="hover:text-gray-500">
                {{ runner.horse.name }}
              </router-link> -->
                <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'"
                  class="font-bold text-[10px] text-[#dc2626] dark:text-red-400 ml-1"
                  title="Oeillères australiennes">(A)</span>
                <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'"
                  class="font-bold text-[12px] text-[#dc2626] dark:text-red-400 ml-1"
                  title="Oeillères pleines"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></span>
                <br />
                <span v-if="runner.ranking" class="text-[10px]">vh:{{ runner.ranking }} |</span>
                <span class="text-[10px] ml-1">{{ runner.horse.sex.slice(0, 1) }} / {{ new Date().getFullYear() - runner.horse.birthyear }}
                  ans</span>
              </td>
              <td class="px-4">
                <OddPmuComponent :referenceOdd=runner.odd_am :lastOdd=runner.odd_pm></OddPmuComponent>
              </td>
              <td class="text-[10px]">
                Jock: <span>{{ runner.rider.name }}</span>
                <span v-if="runner.handicap_weight" class="ml-1">{{ runner.handicap_weight / 10 }}k</span>
                <span v-if="runner.real_weight" class="ml-1 text-gray-500">{{ runner.real_weight / 10 }}k</span><br />
                Entr: {{ runner.trainer.name }}
                <div v-if="runner.corde" class="text-[10px]">Corde:{{ runner.corde }}</div>
                <div v-else-if="runner.handicap_distance" class="text-[10px]">{{ runner.handicap_distance }}m</div>
              </td>
              <td class="text-[10px] px-2">{{ runner.musique }}</td>

              <td class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 0)"
                  :datasetIdKey="ind + ' ' + 0" :key="ind + ' ' + 0" updateMode="show" width=45 height=45 />
              </td>
              <td class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 1)"
                  :datasetIdKey="ind + ' ' + 1" :key="ind + ' ' + 1" updateMode="show" width=45 height=45 />
              </td>
              <td v-if="race.discipline != 'ATTELE' && race.discipline != 'MONTE'" class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 2)"
                  :datasetIdKey="ind + ' ' + 2" :key="ind + ' ' + 2" updateMode="show" width=45 height=45 />
              </td>
              <td class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 3)"
                  :datasetIdKey="ind + ' ' + 3" :key="ind + ' ' + 3" updateMode="show" width=45 height=45 />
              </td>
              <td class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 4)"
                  :datasetIdKey="ind + ' ' + 4" :key="ind + ' ' + 4" updateMode="show" width=45 height=45 />
              </td>
              <td class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 5)"
                  :datasetIdKey="ind + ' ' + 5" :key="ind + ' ' + 5" updateMode="show" width=45 height=45 />
              </td>
              <td class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 6)"
                  :datasetIdKey="ind + ' ' + 6" :key="ind + ' ' + 6" updateMode="show" width=45 height=45 />
              </td>
              <td v-if="race.discipline != 'ATTELE' && race.discipline != 'MONTE' && race.category.includes('HANDICAP')"
                class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 7)"
                  :datasetIdKey="ind + ' ' + 7" :key="ind + ' ' + 7" updateMode="show" width=45 height=45 />
              </td>
              <td class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, 8)"
                  :datasetIdKey="ind + ' ' + 8" :key="ind + ' ' + 8" updateMode="show" width=45 height=45 />
              </td>
            </tr>
          </tbody>
        </div>
      </div>
      <div v-if="showFinalResult">
        <div class="table w-full">
          <thead>
            <tr>
              <th>Class.</th>
              <th>N°</th>
              <th></th>
              <th class="text-left">
                <div class="text-[12px]">Cheval</div>
                <div class="text-[8px]">sexe/âge</div>
              </th>
              <th class="text-center">
                <div class="text-[10px]">Ref</div>
                <div class="text-[10px]">Fin</div>
              </th>
              <th class="text-left">
                <div class="text-[10px]">Jockey</div>
                <div class="text-[10px]">Entraineur</div>
                <div class="text-[8px]">Corde/Dist.</div>
              </th>
              <th class="text-left pl-2">
                <div class="text-[12px]">Ecart</div>
              </th>
              <th class="">
                <font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-map-location-dot" />
              </th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-arrows-left-right" /></th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-cloud-sun-rain" /></th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-user" /></th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-gear" /></th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-trophy" /></th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-sack-dollar" /></th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-weight-scale" /></th>
              <th class=""><font-awesome-icon class="dark:text-gray-600 text-grey-800 text-[24px]"
                  icon="fa-solid fa-calendar" /></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(runner, ind) in runners.sort(byClassement)" :key=ind class="w-full">
              <td class="pt-0.5 pr-2 py-4 text-3xl text-center font-bold">{{
                getIncidentInfo(runner.classement, runner.incident) }}</td>
              <td class="pt-0.5 pr-2 py-4 text-1.5xl text-center font-bold">{{ runner.number }}</td>
              <td>
                <div class="w-[50px] h-[50px] mr-2 dark:bg-gray-700 bg-gray-200 rounded-full"><img :src="runner.url_silks"
                    class="w-[36px] mx-auto block pt-0.5 text-align" alt=""></div>
              </td>
              <td class="pt-0 ml-3 text-[12px] font-medium">
                <span class="cursor-pointer hover:text-gray-500 align-baseline">{{ runner.horse.name }}</span>
                <span v-if=userHorseSelection(runner.horse.id) class="ml-1"><font-awesome-icon class="text-yellow-500" icon="fa-solid fa-star" /></span>
                <span v-else class="ml-1"><font-awesome-icon icon="fa-regular fa-star" /></span>
                <span v-if="runner.def" :class="displayDef(runner.def).color"
                  class="text-[8px] text-gray-50 ml-3 px-1 rounded-full align-middle">{{ displayDef(runner.def).shortLabel
                  }}</span>

                <!-- <router-link :to="{ name: 'FicheCheval',params: { name:runner.horse.name, runnerId: runner.horse.id, date: runner.date }}" target="_blank" class="hover:text-gray-500">
                {{ runner.horse.name }}
              </router-link> -->
                <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'"
                  class="font-bold text-[10px] text-[#dc2626] dark:text-red-400 ml-1"
                  title="Oeillères australiennes">(A)</span>
                <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'"
                  class="font-bold text-[12px] text-[#dc2626] dark:text-red-400 ml-1"
                  title="Oeillères pleines"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></span>
                <br />
                <span v-if="runner.ranking" class="text-[10px]">vh:{{ runner.ranking }} |</span>
                <span class="text-[10px] ml-1">{{ runner.horse.sex.slice(0, 1) }} / {{ new Date().getFullYear() - runner.horse.birthyear }}
                  ans</span>
              </td>
              <td class="px-4">
                <OddPmuComponent :referenceOdd=runner.odd_am :lastOdd=runner.odd_pm></OddPmuComponent>
              </td>
              <td class="text-[10px]">
                Jock: <span>{{ runner.rider.name }}</span>
                <span v-if="runner.handicap_weight" class="ml-1">{{ runner.handicap_weight / 10 }}k</span>
                <span v-if="runner.real_weight" class="ml-1 text-gray-500">{{ runner.real_weight / 10 }}k</span><br />
                Entr: {{ runner.trainer.name }}
                <div v-if="runner.corde" class="text-[10px]">Corde:{{ runner.corde }}</div>
                <div v-else-if="runner.handicap_distance" class="text-[10px]">{{ runner.handicap_distance }}m</div>
              </td>
              <td v-if="runner.horse.race !== 'TROTTEUR FRANCAIS'" class="text-[10px] px-2">{{ runner.distance === null ||
                ind === 0 || runner.classement >= 100 ? '' : runner.distance.short_label }}</td>
              <td v-else class="text-[10px] px-2">{{ formatMs(runner.red_km) }}</td>

              <td v-for="index in 9" :key="ind + ' ' + index" class="table-cell text-center">
                <DoughnutComponent v-if="loaded" :chartOptions="options"
                  :chartData="getData(runner.number - 1, index - 1)" :datasetIdKey="ind + ' ' + index"
                  :key="ind + ' ' + index" updateMode="show" width=45 height=45 class="mx-auto" />
              </td>
            </tr>
          </tbody>
        </div>
      </div>

    </div>
    <!-- <div class="my-2 px-4 divide-y dark:divide-gray-500 dark:text-gray-500 relative">
    <div v-for="(runner, ind) in runners" :key=ind class="w-full">
      <div v-if="ind-1 < 0" class="dark:saturate-0.1 dark:bg-gray-700 dark:font-medium dark:text-gray-900 text-gray-500 shadowed rounded-md py-2 mr-4 flex flex-row gap-0">
        <div class="w-16 pt-0.5 pr-2 text-xl text-center font-bold">N°</div>
        <div class="w-[40px]"></div>
        <div class="w-56 pt-1 ml-3 text-[12px] font-medium">
          Cheval<br/>
          <span class="text-[12px]">sexe/âge</span>
        </div>
        <div class="w-24 px-4 pt-2 text-[12px]">
          <span>Ref</span><br>
          <span>Fin</span>
        </div>
        <div class="w-56 text-[12px]">
          Jockey<br/>
          Entraineur
          <div class="text-[10px]">Corde/Dist.</div>
        </div> 
        <div class="w-56 pt-3.5 text-[12px]">Musique</div>
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-map-location-dot" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-arrows-left-right" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-user" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-gear" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-trophy" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-sack-dollar" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-weight-scale" />
        <font-awesome-icon class="w-24 pt-3 dark:text-gray-900 text-[24px]" icon="fa-solid fa-calendar" />
      </div>
      <div class="dark:saturate-0.1 py-2 flex flex-row gap-0">
        <div class="w-16 pt-0.5 pr-2 text-3xl text-center font-bold">{{ runner.number }}</div>
        <div class="dark:bg-gray-700 bg-gray-200 px-2 rounded-full"><img :src="runner.url_silks" class="w-[40px] h-[35px] mt-1.5" alt="" ></div>
        <div class="w-56 pt-1 ml-3 text-[12px] font-medium">
          <router-link :to="{ name: 'FicheCheval',params: { name:runner.horse.name, runnerId: runner.horse.id, date: runner.date }}" target="_blank">{{ runner.horse.name }}</router-link>
          <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'" class="font-bold text-[10px] text-[#dc2626] dark:text-red-400"  title="Oeillères australiennes">(A)</span> 
          <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'" class="font-bold text-[12px] text-[#dc2626] dark:text-red-400"  title="Oeillères pleines"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></span> 
          <br/>
          <span class="text-[10px]">{{ runner.horse.sex.slice(0,1) }} / {{ 2023 - runner.horse.birthyear }} ans</span> 
        </div>
        <div class="w-24 px-4">
          <OddPmuComponent :referenceOdd=runner.odd_am :lastOdd=runner.odd_pm></OddPmuComponent> 
        </div>
        <div class="w-56 text-[10px]">
          Jock: {{ runner.rider.name }}<br/>
          Entr: {{ runner.trainer.name }}
          <div v-if="runner.corde" class="text-[10px]">Corde:{{ runner.corde }}</div>
          <div v-else-if="runner.handicap_distance" class="text-[10px]">{{ runner.handicap_distance }}m</div>
        </div>           
        <div class="w-56 pt-3.5 text-[10px]">{{ runner.musique }}</div>
         
        <div v-for="index in 9" :key="ind+' '+index" class="w-24 float-left">
            <DoughnutComponent v-if="loaded"
              :chartOptions="options"
              :chartData="getData(ind,index-1)"
              :datasetIdKey="ind+' '+index"
              :key="ind+' '+index"
              updateMode="show"
              width=45
              height=45
            />
        </div>
      </div>
    </div>
  </div> -->
  </div>
  <!-- FIN FORMAT LG -->

  <!-- FORMAT MD > 768px -->
  <div v-if="getFormat() === 'md'">
    <div class="my-2 px-4 divide-y dark:divide-blue-200 dark:text-gray-300 relative">
      <div v-for="(runner, ind) in runners" :key=ind class="w-full">
        <div v-if="ind - 1 < 0"
          class="dark:saturate-0.1 dark:bg-gray-700 dark:font-medium dark:text-gray-400 text-gray-500 shadowed rounded-md py-2 flex flex-row gap-0">
          <div class="w-16 pt-0.5 pr-2 text-xl text-center font-bold">N°</div>
          <div class="w-[40px]"></div>
          <div class="w-56 pt-1 ml-3 text-[12px] font-medium">
            Cheval<br />
            <span class="text-[12px]">sexe/âge</span>
          </div>
          <div class="w-24 px-4 pt-2 text-[12px]">
            <span>Ref</span><br>
            <span>Fin</span>
          </div>
          <div class="w-56 text-[12px]">
            Jockey<br />
            Entraineur
            <div class="text-[10px]">Corde/Dist.</div>
          </div>
          <div class="w-56 pt-3.5 text-[12px]">Musique</div>
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-map-location-dot" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-arrows-left-right" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-user" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-gear" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-trophy" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-sack-dollar" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-weight-scale" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-calendar" />
        </div>
        <div class="dark:saturate-0.1 py-2 mr-3 flex flex-row gap-0">
          <div class="w-16 pt-0.5 pr-2 text-3xl text-center font-bold">{{ runner.number }}</div>
          <img :src="runner.url_silks" class="w-[40px] h-[45px] pt-1" alt="">
          <div class="w-56 pt-1 ml-3 text-[12px] font-medium">
            {{ runner.horse.name }}
            <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'"
              class="font-bold text-[10px] text-[#dc2626] dark:text-red-400" title="Oeillères australiennes">(A)</span>
            <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'"
              class="font-bold text-[12px] text-[#dc2626] dark:text-red-400" title="Oeillères pleines"><font-awesome-icon
                icon="fa-solid fa-circle-xmark" /></span>
            <br />
            <span class="text-[10px]">{{ runner.horse.sex.slice(0, 1) }} / {{ 2023 - runner.horse.birthyear }} ans</span>
          </div>
          <div class="w-20 px-4">
            <OddPmuComponent :referenceOdd=runner.odd_am :lastOdd=runner.odd_pm></OddPmuComponent>
          </div>
          <div class="w-56 text-[10px]">
            Jock: {{ runner.rider.name }}<br />
            Entr: {{ runner.trainer.name }}
            <div v-if="runner.corde" class="text-[10px]">Corde:{{ runner.corde }}</div>
            <div v-else-if="runner.handicap_distance" class="text-[10px]">{{ runner.handicap_distance }}m</div>
          </div>
          <div class="w-56 pt-3.5 text-[10px]">{{ runner.musique }}</div>
          <div v-for="index in 9" :key="ind + ' ' + index" class="w-24 text-[10px] pt-0.5">
            <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, index - 1)"
              :datasetIdKey="ind + ' ' + index" :key="ind + ' ' + index" updateMode="show" width=45 height=45 />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN FORMAT MD -->

  <!-- FORMAT SM < 768px -->
  <div v-if="getFormat() === 'sm'">
    <ToggleButtonComponent class="ml-4" v-model="showFinalResult" option1="Partants" option2="Arrivée" />
    <div class="ml-4 pt-1 text-sm flex-col font-bold"><span
        class="rounded-full border-solid border-[1px] border-gray-400 hover:border-gray-400 bg-white dark:bg-gray-900">📽️
        <a :href="getLink()" target="_blank">Lien replay</a></span></div>
    <div v-if="race.discipline != 'ATTELE' && race.discipline != 'MONTE'" class="ml-4 font-medium">📈 Valeur moyenne du lot
        : {{ averageRating }}</div>
    <div v-if="!showFinalResult && runners?.length > 0">
      <div class="my-2 px-4 divide-y dark:bg-gray-900 dark:divide-blue-200 dark:text-gray-400 relative">
        <div v-for="(runner, ind) in runners.sort(byNumber)" :key=ind class="w-full">
          <div v-if="ind - 1 < 0" class="dark:saturate-0.1 
            dark:bg-gray-700 
            dark:font-medium 
            dark:text-gray-400 
            bg-gray-200 
            text-gray-500
            border-2 border-gray-300 dark:border-gray-600 
            shadowed 
            rounded-sm
            md:rounded-md 
            py-2 
            flex 
            flex-row 
            gap-0">
            <div class="w-12 pt-1 px-2 text-md text-center font-bold">N°</div>
            <!-- <div class="w-[40px]"></div> -->
            <div class="w-[calc(100vw_-_52px)] pt-0 ml-3 text-[12px] font-medium">
              Informations partants
            </div>
            <button class="dark:saturate-0.1 w-full py-2 mr-3 flex flex-row-reverse gap-0 text-right" type="button"
              data-bs-toggle="collapse" :data-bs-target="'.collapse-stat-runners'" aria-expanded="false"
              :aria-controls="'collapseCharts' + ind">
              <font-awesome-icon icon="fa-solid fa-circle-info" />
            </button>
            <div class="w-24 mr-5 text-[12px] text-right font-medium">
              <span>Ref</span><br>
              <span>Fin</span>
            </div>
          </div>
          <!--
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-map-location-dot" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-arrows-left-right" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-user" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-gear" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-trophy" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-sack-dollar" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-weight-scale" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-calendar" />
          -->
          <button class="dark:saturate-0.1 w-full py-2 mr-3 flex flex-row gap-0" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#collapseCharts' + ind" aria-expanded="false" :aria-controls="'collapseCharts' + ind">
            <div class="w-8 text-3xl text-center dark:text-gray-300 font-bold">
              {{ runner.number }}
              <img :src="runner.url_silks" class="w-[30px] pt-1 m-auto" alt="">
            </div>

            <div class="w-[calc(100vw_-_52px)] ml-3 text-[12px] text-left font-medium"
              @click="closeRaceRunnerCard(runner, runners.sort(byNumber))">
              {{ runner.horse.name }}
              <span v-if=userHorseSelection(runner.horse.id) class="ml-1"><font-awesome-icon class="text-yellow-500" icon="fa-solid fa-star" /></span>
              <span v-else class="ml-1"><font-awesome-icon icon="fa-regular fa-star" /></span>
              <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'"
                class="font-bold text-[10px] text-[#dc2626] dark:text-red-400" title="Oeillères australiennes">(A)</span>
              <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'"
                class="font-bold text-[12px] text-[#dc2626] dark:text-red-400" title="Oeillères pleines"><font-awesome-icon
                  icon="fa-solid fa-circle-xmark" /></span>
              <span v-if="runner.def" :class="displayDef(runner.def).color"
                class="text-[8px] text-gray-50 ml-3 px-1 rounded-full align-middle">{{ displayDef(runner.def).shortLabel
                }}</span>
              <br />
              <span class="text-[10px]">{{ runner.horse.sex.slice(0, 1) }} / {{ new Date().getFullYear() - runner.horse.birthyear }} ans</span>

              Jock: {{ runner.rider.name }}<br />
              Entr: {{ runner.trainer.name }}
              <div class="text-[10px]"><span v-if="runner.corde">Corde:{{ runner.corde }}</span><span v-if="runner.ranking">
                  - VH:{{ runner.ranking }}</span></div>
              <div v-if="runner.handicap_distance" class="text-[10px]">{{ runner.handicap_distance }}m</div>
              <div class="">{{ runner.musique }}</div>
            </div>

            <div class="w-5 pt-5 mr-4">
              <OddPmuComponent :referenceOdd=runner.odd_am :lastOdd=runner.odd_pm></OddPmuComponent>
            </div>
          </button>
          <div class="collapse collapse-stat-runners" :id="'collapseCharts' + ind">
            <div class="relative">
              <div pb-8>
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-map-location-dot" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-arrows-left-right" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-user" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-gear" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-trophy" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-sack-dollar" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-weight-scale" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-calendar" />
              </div>
              <div class="block absolute top-8 left-0 center">
                <div v-for="index in 9" :key="ind + ' ' + index" class="w-16 pl-2.5 text-[10px] float-left pb-11">
                  <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(ind, index - 1)" :width=45 :height=45 :top=10 
                    :datasetIdKey="ind + ' ' + index" :key="ind + ' ' + index" updateMode="show"/>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div v-if="showFinalResult && runners?.length > 0">
      <div class="my-2 px-4 divide-y dark:bg-gray-900 dark:divide-blue-200 dark:text-gray-400 relative">
        <div v-for="(runner, ind) in runners.sort(byClassement)" :key=ind class="w-full">
          <div v-if="ind - 1 < 0" class="dark:saturate-0.1 
            dark:bg-gray-700 
            dark:font-medium 
            dark:text-gray-400 
            bg-gray-200 
            text-gray-500
            border-2 border-gray-300 dark:border-gray-600 
            shadowed 
            rounded-sm
            md:rounded-md 
            py-2 
            flex 
            flex-row 
            gap-0">
            <div class="w-12 pt-1 px-2 text-md text-center font-bold">N°</div>
            <!-- <div class="w-[40px]"></div> -->
            <div class="w-[calc(100vw_-_52px)] pt-0 ml-3 text-[12px] font-medium">
              Informations partants
            </div>
            <button class="dark:saturate-0.1 w-full py-2 mr-3 flex flex-row-reverse gap-0 text-right" type="button"
              data-bs-toggle="collapse" :data-bs-target="'.collapse-stat-runners'" aria-expanded="false"
              :aria-controls="'collapseCharts' + ind">
              <font-awesome-icon icon="fa-solid fa-circle-info" />
            </button>
            <div class="w-24 mr-5 text-[12px] text-right font-medium">
              <span>Ref</span><br>
              <span>Fin</span>
            </div>
          </div>
          <!--
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-map-location-dot" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-arrows-left-right" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-user" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-gear" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-trophy" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-sack-dollar" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-weight-scale" />
          <font-awesome-icon class="w-24 pt-3 dark:text-gray-400 text-[24px]" icon="fa-solid fa-calendar" />
          -->
          <button class="dark:saturate-0.1 w-full py-2 mr-3 flex flex-row gap-0" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#collapseCharts' + ind" aria-expanded="false" :aria-controls="'collapseCharts' + ind">
            <div class="w-8 text-3xl text-center dark:text-gray-300 font-bold">
              {{ runner.number }}
              <img :src="runner.url_silks" class="w-[30px] pt-1 m-auto" alt="">
            </div>

            <div class="w-[calc(100vw_-_52px)] ml-3 text-[12px] text-left font-medium"
              @click="closeRaceRunnerCard(runner, runners.sort(byNumber))">
              {{ runner.horse.name }}
              <span v-if=userHorseSelection(runner.horse.id) class="ml-1"><font-awesome-icon class="text-yellow-500" icon="fa-solid fa-star" /></span>
              <span v-else class="ml-1"><font-awesome-icon icon="fa-regular fa-star" /></span>
              <span v-if="runner.blinkers === 'OEILLERES_AUSTRALIENNES'"
                class="font-bold text-[10px] text-[#dc2626] dark:text-red-400" title="Oeillères australiennes">(A)</span>
              <span v-if="runner.blinkers === 'OEILLERES_CLASSIQUE'"
                class="font-bold text-[12px] text-[#dc2626] dark:text-red-400" title="Oeillères pleines"><font-awesome-icon
                  icon="fa-solid fa-circle-xmark" /></span>
              <span v-if="runner.def" :class="displayDef(runner.def).color"
                class="text-[8px] text-gray-50 ml-3 px-1 rounded-full align-middle">{{ displayDef(runner.def).shortLabel
                }}</span>
              <br />
              <span class="text-[10px]">{{ runner.horse.sex.slice(0, 1) }} / {{ new Date().getFullYear() - runner.horse.birthyear }} ans</span>

              Jock: {{ runner.rider.name }}<br />
              Entr: {{ runner.trainer.name }}
              <div class="text-[10px]"><span v-if="runner.corde">Corde:{{ runner.corde }}</span><span v-if="runner.ranking">
                  - VH:{{ runner.ranking }}</span></div>
              <div v-if="runner.handicap_distance" class="text-[10px]">{{ runner.handicap_distance }}m</div>
              <div class="">{{ runner.musique }}</div>
            </div>

            <div class="w-5 pt-5 mr-4">
              <OddPmuComponent :referenceOdd=runner.odd_am :lastOdd=runner.odd_pm></OddPmuComponent>
            </div>
          </button>
          <div class="collapse collapse-stat-runners" :id="'collapseCharts' + ind">
            <div class="relative">
              <div pb-8>
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-map-location-dot" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-arrows-left-right" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-cloud-sun-rain" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-user" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-gear" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-trophy" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-sack-dollar" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-weight-scale" />
                <font-awesome-icon class="w-16 pb-16 dark:text-gray-400 text-[24px]" icon="fa-solid fa-calendar" />
              </div>
              <div class="block absolute top-8 left-0 center">
                <div v-for="index in 9" :key="ind + ' ' + index" class="w-16 pl-2.5 text-[10px] float-left pb-11">
                  <DoughnutComponent v-if="loaded" :chartOptions="options" :chartData="getData(runner.number - 1, index - 1)" :width=45 :height=45 :top=10 
                    :datasetIdKey="ind + ' ' + index" :key="ind + ' ' + index" updateMode="show"/>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- FIN FORMAT SM -->
</template>

<script setup>
import { getAverageRating } from '@/services/utils/utilsRunnersTools';
import OddPmuComponent from './OddPmuComponent.vue'
import ToggleButtonComponent from './ToggleButtonComponent.vue'
import DoughnutComponent from './DoughnutComponent.vue'
import { ref, defineEmits, onMounted } from 'vue';
import { orderStatCriteriasData, getClassementFromRunner } from '../services/utils/orderStatCriteriasData.js'
import { turfissimo } from '@/services/turfissimo'
import { handlerSession } from '@/services/utils/sessionHandler'
import { meetingView, selectedPastResult, selectedRace, selectedRunner } from '@/stores'
//import { useRouter } from 'vue-router';

//const router = useRouter();

const emits = defineEmits(['resetAskForRaceCard', 'get-past-results'])

const selectRaceState = selectedRace();
const selectedRunnerState = selectedRunner();
const selectedPastResultState = selectedPastResult();
const meetingViewState = meetingView();
const runners = ref(selectedRace().getRunners);
const statCriterias = ref(selectRaceState.getStats);
const race = ref(selectRaceState.getInfos);

const userHorseSelection = (horseId) => {
  const storage = JSON.parse(localStorage.getItem('turfissimo_user'))
  let selected = false
  storage?.horses.map((element) => {    
    if(element.id == horseId){
      selected = true
    }
  })
  return selected
}

const averageRating = ref(runners.value?.length > 0 ? getAverageRating(runners.value) : 0);

//const askForRaceCard = ref(false);
const showFinalResult = ref(false);

const loaded = ref(false);

// const selectedContent = ref('partants');
// const contents = [
//   { value: 'partants', label: 'Partants' },
//   { value: 'arrivée', label: 'Arrivée' },
// ];
//const displayContent = computed(() => contents.map(c => c.value).includes(selectedContent.value));

// const odds = ref({})
// const oddPmuFromApi = async (race) => {
//   let date = new Date(race.date)
//   let year = date.getFullYear() * 1e4
//   let month = (date.getMonth() + 1) * 100
//   let day = date.getDate()
//   date = year + month + day + ''
//   try {
//     //let response = await axios.get('https://offline.turfinfo.api.pmu.fr/rest/client/61/programme/'+date+'/'+num_meeting+'/'+num_race+'/rapports/E_SIMPLE_GAGNANT')
//     //odds.value = response.data
//   } catch (e) {
//     console.log(e.message)
//   }
// }
// const orderData = async(stats) => {
//   return orderStatCriteriasData(stats)
// }

const options = ref({
  responsive: false,
  maintainAspectRatio: false,
  animation: {
    duration: 350,
    delay: 400,
    easing: 'easeOutCirc'
  },
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
      text: '5',
      align: 'align',
      fullSize: false
    }
  }
})

const getData = (ind, index) => {
  return {
    labels: ['GAG', 'PLA', 'ITM', 'NPL'],
    datasets: [
      {
        backgroundColor: ['#166534', '#84cc16', '#fb923c', '#dc2626'],
        borderColor: '#222',
        cutout: '70%',
        animation: {
          animateRotate: true,
          animateScale: true
        },
        data: statCriterias.value[ind][index]
      }
    ]
  }
}

const getLink = () => {
  return ("https://www.equidia.fr/courses/" +
    new Date(race.value.date).toISOString().split("T")[0] +
    "/R" +
    race.value.num_meeting +
    "/C" +
    race.value.num_race +
    "#player")
}

const getIncidentInfo = (classement, incident) => {
  return getClassementFromRunner(classement, incident)
}

const displayDef = (def) => {
  if (def === 'DEFERRE_ANTERIEURS_POSTERIEURS') return { shortLabel: 'D4', color: 'bg-red-800' }
  if (def === 'DEFERRE_POSTERIEURS') return { shortLabel: 'DP', color: 'bg-green-800' }
  if (def === 'DEFERRE_ANTERIEURS') return { shortLabel: 'DA', color: 'bg-green-800' }
  if (def === 'DEFERRE_ANTERIEURS_PROTEGE_POSTERIEURS') return { shortLabel: 'DAPP', color: 'bg-orange-800' }
  if (def === 'PROTEGE_ANTERIEURS_DEFERRRE_POSTERIEURS') return { shortLabel: 'PADP', color: 'bg-orange-800' }
  if (def === 'PROTEGE_ANTERIEURS_POSTERIEURS') return { shortLabel: 'P4', color: 'bg-orange-800' }
  if (def === 'PROTEGE_ANTERIEURS') return { shortLabel: 'PA', color: 'bg-orange-800' }
  if (def === 'PROTEGE_POSTERIEURS') return { shortLabel: 'PP', color: 'bg-orange-800' }
}

function getFormat() {
  //console.log('getFormat')
  if (window.innerWidth < 769) {
    //console.log('sm')
    return 'sm'
  } else if (window.innerWidth < 1000) {
    //console.log('md')
    return 'md'
  } else {
    //console.log('lg')
    return 'lg'
  }
}

function closeRaceRunnerCard(runner, runners) {
  //router.push({ name: 'RunnerPast', params: { 'horseId': runner.horse_id, 'horseSlug': runner.horse.name.replace(/\s/g, '-').toLowerCase() } });
  emits('get-past-results', runner.horse.name, runner.horse.id, runner.date, runners, runner.number, statCriterias.value)
}

function byClassement(a, b) {
  if (a.classement < b.classement) {
    return -1;
  }
  if (a.classement > b.classement) {
    return 1;
  }
  return 0;
}

function byNumber(a, b) {
  if (a.number < b.number) {
    return -1;
  }
  if (a.number > b.number) {
    return 1;
  }
  return 0;
}

function formatMs(milliseconds) {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  const ms = milliseconds % 1000;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}:${ms < 100 ? '0' : ''}${ms < 10 ? '0' : ''}${ms}`;
}

onMounted(async () => {
  loaded.value = false
  //askForRaceCard.value = true
  try {
    const response = await turfissimo.getRaceData(meetingViewState.getSelectedRace)
    selectRaceState.loadRunners(response.data)
  } catch (e) {
    selectRaceState.resetState();
    console.log(e.message)
  }
  //window.addEventListener("resize", this.getFormat);
  if (handlerSession.getSessionStorageItem('globalStats-' + meetingViewState.getSelectedRace) === null) {
    try {
      const response = await turfissimo.getGlobalStats(meetingViewState.getSelectedRace)
      handlerSession.setSessionStorageItemWithExpiration('globalStats-' + meetingViewState.getSelectedRace, JSON.stringify(response.data), 2)
      loaded.value = true
      selectRaceState.loadStats(orderStatCriteriasData(response.data));
    } catch (e) {
      selectRaceState.resetState();
      selectedRunnerState.resetState();
      selectedPastResultState.resetState();
      console.log(e.message)
    }
  } else {
    const response = handlerSession.getSessionStorageItem('globalStats-' + meetingViewState.getSelectedRace)
    loaded.value = true
    selectRaceState.loadStats(orderStatCriteriasData(JSON.parse(response)))
  }

  runners.value = selectedRace().getRunners;
  statCriterias.value = selectRaceState.getStats;
  race.value = selectRaceState.getInfos;
  averageRating.value = runners.value?.length > 0 ? getAverageRating(runners.value) : 'NA';
})


</script>

<style>
[v-cloak] {
  display: none;
}
</style>