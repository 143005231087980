<template>
  <div
    class="h-[calc(100vh-3.5em)] w-screen mt-8 bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-50 "
  >
    <div v-if="errors != null">
      <div v-for="e in errors" :key="e">
        <div>{{ e }}</div>
      </div>
    </div>
    <div class="flex flex-cols md:items-center md:justify-center h-[calc(100vh-3.5em)]">
      <form
        class="md:w-[50%] xl:w-[35%] rounded-lg border-2 dark:border-gray-800 shadow-lg grid grid-cols-1 bg-white dark:bg-gray-900 text-gray-600 dark:text-gray-400 p-2">
        <h3 class="text-xl font-semibold mx-auto">Se connecter</h3>
        <br />
        <div class="p-3">
          <label for="email">Email</label><input type="text" v-model="form.email" placeholder="email@email.com"
            class="float-right pl-1 dark:bg-gray-900 dark:border-gray-600 border-gray-200 border-solid border-2 rounded-md"
            required />
        </div>
        <div class="p-3">
          <label for="password">Mot de passe</label><input type="password" v-model="form.password" placeholder="password"
            class="float-right pl-1  dark:bg-gray-900 dark:border-gray-600 border-gray-200 border-solid border-2 rounded-md"
            required />
        </div>
        <div class="px-3 py-1 mx-auto bg-green-700 hover:bg-green-800 text-gray-100 rounded-md font-semibold">
          <button @click.prevent="loginUser" type="submit" variant="success">
            Se connecter
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { turfissimo } from "../../services/turfissimo";
import { useRouter } from "vue-router";

export default {
  name: "SigninView",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      router: useRouter(),
      errors: [],
    };
  },
  methods: {
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },


    async loginUser() {
      await turfissimo.login(this.form)
        .then(() => {
          this.router.back();
          //window.location.reload();
        })
        .catch((e) => {
          this.errors.push({
            message: e.response.data,
            status: e.response.status
          })
        });
    },
  },
};
</script>