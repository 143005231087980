<template>
<div class="h-[calc(100vh-1.5em)] mt-0 bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-400">
    <div>
        <div class="
            sm:float-left
            overflow-auto 
            px-2 
            h-[calc(100vh_-_1.5rem)] 
            bg-gray-50 
            dark:bg-gray-900 
            dark:text-gray-400"
            :class="[params?.R ? 'hidden md:block' : 'block md:block']"
        >
            <div class="p-2 pt-[46px] relative">
                <!-- <h1>Les réunions du jour</h1> -->
                <Datepicker 
                    :model-value="date" 
                    @update:model-value="setDate"
                    :enable-time-picker="false" 
                    :month-change-on-scroll="false"
                    format="dd/MM/yyyy"
                    locale="fr"
                    cancelText="" 
                    selectText="Sélectionner"
                    auto-apply
                    :dark=appDatasState.getDarkMode
                    class="shadow-md
                    bg-gray-50 
                    dark:bg-gray-900 
                    dark:text-gray-400"
                />
            </div>
            <div v-if="showMeetingLoader" class="w-[310px] text-center mt-[30vh]">
                <!-- <div class="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div> -->
                <!-- <div class="flex items-center justify-center">
                    <div
                        class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                        >
                    </div>
                </div> -->
                <LoaderComponent/>
            </div>
            <div v-else class="grid grid-cols-1">
                <MeetingListView
                :meetings=meetingViewState.getMeetings
                />
            </div>
        </div>
        
        
        <div v-if="params?.R" 
            id="PastResult"
            class="md:w-[calc(100%_-_345px)]
            h-[calc(100vh_-_1.5rem)] 
            sm:float-left
            pt-[32px]
            md:pt-4
            bg-gray-50 
            dark:bg-gray-900 
            text-gray-800
            dark:text-gray-400
            overflow-auto
            [params?.R ?'sm:block md:block' : 'sm:hidden md:block']
            "
            >
            <runner-view/>
        </div>
        <div v-else class="pt-[45px]">
            <!-- <ToggleButtonComponent v-model="statActor" option1="Forme" option2="Statistiques" /> -->
            <div v-if="!statActor" class="hidden md:block px-2">
                <div class="text-center pb-10">
                    <h1 class="text-4xl font-bold">🚀🚀🚀 Les acteurs du moment 🚀🚀🚀</h1>
                    <em class="text-sm font-italic font-light">établis sur les {{ durationLimit }} derniers jours glissants</em>
                </div>
                <div class="flex flex-row pb-2">
                    <div :class=checkSelectedButton(2) @click=getStatistics(2) class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>2 jours</button></div>
                    <div :class=checkSelectedButton(7) @click=getStatistics(7) class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>7 jours</button></div>
                    <div :class=checkSelectedButton(15) @click=getStatistics(15) class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>15 jours</button></div>
                    <div :class=checkSelectedButton(30) @click=getStatistics(30) class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>30 jours</button></div>
                </div>
                <div class="grid grid-flow-col auto-cols-fr">
                    <div class="border-[0px] border-gray-400 rounded-md bg-white dark:bg-gray-800 shadow-md m-2" v-for="(currentDiscipline, discipline) in currentFormStatistics" :key=discipline>
                        <div class="m-3">                            
                            <div class="text-2xl font-bold pb-3 text-center">{{ discipline.toUpperCase() }}</div>
                            <div v-for="(currentActor, actor) in currentDiscipline" :key=actor>
                                <div class="text-xl font-medium pt-3 pb-1"><h2>{{ actor=='riders'?'🏇 Jockeys':'🎩 Entraineurs' }}</h2></div>
                                <span class="text-sm" v-for="(_actor, idx) in currentActor" :key=idx>
                                    {{ discipline != 'trot' ? _actor.name.replace('.','. ') : _actor.name }}- {{ _actor.nombre_de_classement_1 }} victoires <br>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="statActor" class="hidden md:block px-2">

                <h1 class="text-4xl text-center font-bold pb-5">📈 Les statistiques à la demande 📉</h1>
                <section class="flex pb-2">
                    <h2 class="text-xl mx-2">Thématiques</h2>
                    <div class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>Discipline</button></div>
                    <div class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>Hippodrome</button></div>
                    <div class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>Jockeys</button></div>
                    <div class="mx-2 px-4 dark:bg-gray-800 border-2 rounded-full dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"><button>Entraineurs</button></div>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { ref, defineProps, onMounted, watchEffect } from 'vue';
import MeetingListView from './MeetingListView.vue';
import RunnerView from './RunnerView.vue';
import { turfissimo } from '@/services/turfissimo';
import { handlerSession } from '@/services/utils/sessionHandler'
import { useRouter, onBeforeRouteUpdate } from 'vue-router';
import { selectedRace, meetingView, appDatas } from '@/stores'
import LoaderComponent from '@/components/LoaderComponent.vue';
//import ToggleButtonComponent from '@/components/ToggleButtonComponent.vue'

const props = defineProps({
    date: String,
    R: String,
    C: String,
    horseId: [Number, String]
})


const router = useRouter();

const selectedRaceState = selectedRace();
const meetingViewState = meetingView();
const appDatasState = appDatas();

const showMeetingLoader = ref(true);
const params = ref(router.currentRoute.value.params);
const currentFormStatistics = ref(null)

const durationLimit = ref(7)

const statActor = ref(false)

//const darkMode = ref(appDatasState.getDarkMode)

const setDate = (modelData) => {
    showMeetingLoader.value = !showMeetingLoader.value
    router.push({ name: 'Dashboard', params: { date: modelData.toISOString().slice(0,10) } })
    getDate(modelData.toISOString().slice(0,10))
}

const getDate = async (date) => {
    const getRace = props?.R && props?.C
    const formatDate = date

    if(handlerSession.getSessionStorageItem('meetings-'+formatDate) === null){
        await turfissimo.getMeeting(formatDate)
        .then((response) => {
            const sessionDurationInMinutes = 2
            handlerSession.setSessionStorageItemWithExpiration('meetings-'+formatDate, JSON.stringify(response.data),sessionDurationInMinutes);
            showMeetingLoader.value = !showMeetingLoader.value
            meetingViewState.loadMeetings(response.data)
            if(getRace) {
                handleRace();
            }
        })
        
    } else {
        showMeetingLoader.value = !showMeetingLoader.value
        meetingViewState.loadMeetings(JSON.parse(handlerSession.getSessionStorageItem('meetings-'+formatDate)));
        if(getRace) {
            handleRace();
        }
    }
      
}

const handleRace = (() => {
    let raceTrouvee = null;
    for (let i = 0; i < meetingViewState.getMeetings.length; i++) {
        if(!raceTrouvee){
            let meeting = meetingViewState.getMeetings[i];
            let races = meeting.races;
            for(let j = 0; j < races.length; j++){
                if (races[j].num_meeting == props?.R && races[j].num_race == props?.C) {
                    raceTrouvee = races[j];
                    break; // Si trouvé, sortir de la boucle
                }
            }
        }
    }

    if (raceTrouvee !== null) {
        selectedRaceState.loadInfos(raceTrouvee)
    } else {
        selectedRaceState.resetState()
        console.log("Aucune race trouvée pour les critères donnés.");
    }
});

const getStatistics = async (duration) => {
    durationLimit.value = duration
    await turfissimo.getCurrentFormStatistic(duration)
    .then((response) => {
        currentFormStatistics.value = response.data
    })
}

const checkSelectedButton = (newDuration) => {
    if(durationLimit.value == newDuration){
        return "bg-gray-200 dark:bg-gray-600"
    }
    return "bg-gray-50"
}

onMounted(() => {
    getDate(props.date)
    getStatistics(7)
})

onBeforeRouteUpdate(async function(to) {
    let raceTrouvee = null;
    if(to.params.C){      
        for (let i = 0; i < meetingViewState.getMeetings?.length; i++) {
            if(!raceTrouvee){
                let meeting = meetingViewState.getMeetings[i];
                let races = meeting.races;
                for(let j = 0; j < races.length; j++){
                    if (races[j].num_meeting == to.params?.R && races[j].num_race == to.params?.C) {
                        raceTrouvee = races[j];
                        break; // Si trouvé, sortir de la boucle
                    }
                }
            }
        }
    }

    if (raceTrouvee !== null) {
        selectedRaceState.loadInfos(raceTrouvee)
    } else {
        selectedRaceState.resetState()
    }
})

watchEffect(() => {
    params.value = router.currentRoute.value.params
})
</script>

<style>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>