<template>
	<div id="article-component" class="sm:w-full md:w-[50%] mx-auto">
		<div class="container" v-if="articles.length>0">
			<div>
				<h1 v-text="articles[0].title" class="text-4xl font-bold"></h1>
				<br>
				<i class="float-right" v-text="new Date(articles[0].created_at).toLocaleDateString()"></i>
				<br>
				<br>
				<div id="content-article" v-html="articles[0].content"></div>
			</div>
		</div>
	</div>
</template>

<style type="text/css">
	.ql-editor p img {
		max-width: 100%;
        margin: auto;
	}
</style>

<script>
import { turfissimo } from '@/services/turfissimo'
export default {
    beforeMount() {
        this.articles = []
        //axios.get(this.$root.$root.url+'/api/blog?fields[articles]=id,title,accroche,url_image,content,created_at&filter[id]='+this.$route.params.article_id)
        turfissimo.getArticle(this.$route.params.article_id)
        .then(response => {
            this.articles = response.data
            document.getElementsByTagName("META")["twitter:title"].content = this.articles[0].title;
            document.getElementsByTagName("META")["twitter:description"].content = this.articles[0].accroche.slice(0,197)+"...";
            document.getElementsByTagName("META")["twitter:image:src"].content = this.articles[0].url_image;
        });
    },
    data: function(){
        return {
            articles : []
        }
    }
}
</script>