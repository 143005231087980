export function orderStatCriteriasData(stats) {
    let dataHorse = []
    stats.forEach((elements, i) => {
        dataHorse[i] = []
        elements.forEach((element, j) => {
            dataHorse[i][j] = [
                element.gagnant,
                element.place - element.gagnant,
                element.itm - element.place,
                element.total - element.itm,
            ]
        });
    });
    return dataHorse
}

export function getClassementFromRunner(classement, incident) {
    if (classement < 100) {
        return classement;
    }
    if (classement == 100) return incident ? capitalize(incident) : ''
    if (classement == 101) return 'NP'
}

export function capitalize(str, nbchar = 3) {
    if (str === null) {
        return str
    }
    return str.slice(0, nbchar).charAt(0).toUpperCase() + str.slice(0, nbchar).toLowerCase().slice(1)
}

export function displayDef(def) {
    if (def === 'DEFERRE_ANTERIEURS_POSTERIEURS') return { shortLabel: 'D4', color: 'bg-red-800' }
    if (def === 'DEFERRE_POSTERIEURS') return { shortLabel: 'DP', color: 'bg-green-800' }
    if (def === 'DEFERRE_ANTERIEURS') return { shortLabel: 'DA', color: 'bg-green-800' }
    if (def === 'DEFERRE_ANTERIEURS_PROTEGE_POSTERIEURS') return { shortLabel: 'DAPP', color: 'bg-orange-800' }
    if (def === 'PROTEGE_ANTERIEURS_DEFERRRE_POSTERIEURS') return { shortLabel: 'PADP', color: 'bg-orange-800' }
    if (def === 'PROTEGE_ANTERIEURS_POSTERIEURS') return { shortLabel: 'P4', color: 'bg-orange-800' }
    if (def === 'PROTEGE_ANTERIEURS') return { shortLabel: 'PA', color: 'bg-orange-800' }
    if (def === 'PROTEGE_POSTERIEURS') return { shortLabel: 'PP', color: 'bg-orange-800' }
}

export function getRedKm(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const tenths = Math.floor((milliseconds % 1000) / 100);

    const formattedTime = `${minutes}'${seconds}"${tenths}`;

    return formattedTime;
}

export function getStyleCategory(comment) {
    if (
        /(à l'arrière du peloton|dans le dernier tiers du peloton|de l'arrière-garde|à l'arrière-garde|antépénultième|pointé au dernier rang|patienté à l'arrière-garde|lanterne rouge|fermé la marche|dernier du peloton|encore dernier|patient aux derniers rangs|patient au dernier rang|toujours aux derniers rangs|toujours au dernier rang|attentiste|en retrait|venu du dernier tiers|venu du derniers tiers|venu des derniers|en dernière position|en queue de peloton|en fin de peloton|dernier dans le peloton|vient en dernier lieu|parmi les derniers|se fait ramener|est venu des derniers rangs|avant-dernier|venu de l'arrière)/i.test(
            comment
        )
    ) {
        return 4; // "attentiste";
    } else if (
        /(vite derrières les premiers|toujours en bon rang|le dos de l'animat|quatrième durant le parcours|quatrième position|cinquième position|sixième position|vite en bon rang|derrière le groupe de tête|en embuscade|sillage du coanimat|deuxième ligne|troisième position|a proximité des leaders|derrière les leaders|derriere les leaders|dans le sillage des leaders|dans le sillage des premiers|dans le sillage de l'animat|derrière l'animat|derrière les animat|dans le sillage des animat|dans le groupe de tête|dans le groupe de tête)/i.test(
            comment
        )
    ) {
        return 2; // "derrière les leaders";
    } else if (
        /(à la pointe du combat|au côté de l'animateur|a animé l'épreuve|a animé la course|grande animat|grand animat|installé au commandement|installée au commandement|extérieur de l'animateur|animateur|rabattu au commandement|rabattu en tête|en tête|en tete|en tête dès le départ|vite aux avants-postes|vite aux avant-postes|au contact de l'animateur|accompagné l'animateur|en tete des le depart|en tête du peloton|en tete du peloton|tête et corde|pris la direction des|vite en action|Vite en action|vite en tête|Vite en tête|Aux avant-postes|aux avant-postes|Rapidement aux premiers rangs|rapidement aux premiers rangs|imposé son rythme|extérieur de l'animat)/i.test(
            comment
        )
    ) {
        return 1; // "course en tête";
    } else if (
        /(n'a joué aucun rôle|venu du sein du peloton|mi-peloton|seconde moitié du peloton|troisième ligne|quatrième ligne|sur un troisième rideau|sur une troisième ligne|au sein du peloton|au milieu du peloton|dans le peloton|au cœur du peloton|dans le ventre du peloton|vite derrière les premiers|toujours en bon rang|le dos de l'animat|quatrième durant le parcours|quatrième position|cinquième position|sixième position|vite en bon rang|derrière le groupe de tête|en embuscade|sillage du coanimat|deuxième ligne|troisième position|à proximité des leaders|derrière les leaders|dans le sillage des leaders|dans le sillage des premiers|dans le sillage de l'animat|derrière l'animat|derrière les animat|dans le sillage des animat|dans le groupe de tête|dans le sillage|non loin du groupe de tête|tiers du|e ligne)/i.test(
            comment
        )
    ) {
        return 3; //"au sein du peloton";
    } else {
        return 5;
    }
}

export function getClassTW(classement, fromStyle = false) {
    let color = "text-red-600";
    if (classement <= 5) {
        color = "text-orange-500";
    }
    if (classement <= 3) {
        color = "text-lime-500";
    }
    if (classement == 1) {
        color = "text-green-800";
    }
    return fromStyle ? color : color + " text-base w-[3px] h-[3px] font-bold";
}
