<template>
  <div
    class="p-6 text-center relative overflow-hidden min-h-screen h-full dark:bg-gray-900 dark:text-gray-50"
    id="learnMore"
  >
    <h1 class="font-bold text-5xl mt-10 mb-10">En savoir plus</h1>
    <div class="grid xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mx-auto">
      <home-card-component 
          title="Statistiques 📈 " 
          description="Des statistiques pour l'ensemble des partants sur toutes les courses du jour"
          buttonName="Voir les courses du jour"
          link="Dashboard"
          :params=parameters
          image="statistiques.png"
          />
      <!-- <home-card-component 
          title="Stratégies 🕵 " 
          description="Des stratégies que vous pouvez vous même mettre en place selon vos propres critères de sélections"
          buttonName="Voir les stratégies gratuites"
          link="Strategy"
          :params=null
          image="strategies.png"
          /> -->
      <home-card-component 
          title="Blog 📝 " 
          description="Des articles sur des études statistiques permettant d'affirmer ou pas certaines hypothèses"
          buttonName="Voir les articles du blog"
          link="Blog"
          :params=null
          image="blog.png"
          />
    </div> 
  </div>
</template>

<script setup>
import HomeCardComponent from './HomeCardComponent.vue'

const parameters = { date: new Date().toISOString().slice(0,10) }

</script>

<style>

</style>