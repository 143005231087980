import { defineStore } from 'pinia'

export const selectedPastResult = defineStore('details-view', {
    state: () => (
        {
            raceDetails: null,
            raceInfo: null
        }
    ),
    getters: {
        getRaceDetails: (state) => state.raceDetails,
        getRaceInfo: (state) => state.raceInfo
    },
    actions: {
        loadRaceDetails(raceDetails){
            this.raceDetails = raceDetails
        },
        loadRaceInfo(raceInfo){
            this.raceInfo = raceInfo
        },
        resetState(){
            this.raceDetails = null,
            this.raceInfo = null
        }
    }
})

export const selectedRunner = defineStore('runner-view', {
    state: () => (
        {
            pastResults: null
        }
    ),
    getters: {
        getPastResults: (state) => state.pastResults
    },
    actions: {
        loadPastResults(pastResults) {
            this.pastResults = pastResults;
        },
        resetState() {
            this.pastResults = null;
        }
    }
})

export const selectedRace = defineStore('race-view', {
    state: () => (
        {
            infos: null,
            runners: null,
            stats: null
        }
    ),
    getters: {
        getInfos: (state) => state.infos,
        getRunners: (state) => state.runners,
        getStats: (state) => state.stats
    },
    actions: {
        loadInfos(infos) {
            this.infos = infos
        },
        loadRunners(runners) {
            this.runners = runners
        },
        loadStats(stats) {
            this.stats = stats
        },
        resetState() {
            this.infos = null
            this.runners = null
            this.stats = null
        }

    },

})

export const meetingView = defineStore('meeting-view', {
    state: () => (
        {
            selectedMeeting: null,
            selectedRace: null,
            meetings: null
        }
    ),
    getters: {
        getSelectedMeeting: (state) => state.selectedMeeting,
        getSelectedRace: (state) => state.selectedRace,
        getMeetings: (state) => state.meetings
    },
    actions: {
        loadSelectedMeeting(id) {
            this.selectedMeeting = id;
        },
        loadSelectedRace(id) {
            this.selectedRace = id;
        },
        loadMeetings(meetings) {
            this.meetings = meetings;
        },
        resetSelectedMeeting() {
            this.selectedMeeting = null;
        },
        resetSelectedRace() {
            this.selectedRace = null
        }
    }
})

export const strategies = defineStore('strategies', {
    state: () => (
        {
            strategies: null,
            todaySelection: null
        }
    ),
    getters: {
        getStrategies: (state) => state.strategies,
        getTodaySelection: (state) =>state.todaySelection
    },
    actions: {
        loadStrategies(strategies) {
            this.strategies = strategies
        },
        loadTodaySelection(selection) {
            this.todaySelection = selection
        },
        resetStrategies() {
            this.strategies = null
        }
    }
})

export const appDatas = defineStore('app-datas',{
    state: () => (
        {
            darkMode: false,
            userToken: null,
            userInfos: null
        }
    ),
    getters: {
        getDarkMode: (state) => state.darkMode,
        getUserToken: (state) => state.userToken,
        getUserInfos: (state) => state.userInfos
    },
    actions: {
        switchDarkMode() {
            this.darkMode = !this.darkMode
        },
        setUserToken(token) {
            this.userToken = token
        },
        setUserInfos(infos) {
            this.userInfos = infos
        },
        disconnect() {
            this.userToken = null
            this.userInfos = null
        }
    }
})